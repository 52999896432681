<rkt-stack class="rkt-CenteredColumn stack do-not-sell-container" id="top">
    <div
      class="split-stack-container" splitAt="2">
      <div
        rktStackItem
        class="rkt-Stack__item--fourth@2 fourth rkt-Stack--center-all"
      ></div>
      <div
        rktStackItem
        class="rkt-Stack__item--half@2 half rkt-Stack--center-all">
        <!-- rocket logo -->
        <img *ngIf="!showNewLogo" class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg">
        <!-- new rocket logo -->
        <img *ngIf="showNewLogo" class="rocket-new-logo" [src]="logoUrl">
        <!-- headers and copy -->
        <div rktStackItem id="do-not-sell-content" class="header-title">
          <h1 isPageTitle="true" class="rkt-Heading-40 rkt-Spacing--mb16" >Do Not Sell or Share</h1>
          <h1 class="rkt-Heading-40 rkt-Spacing--mb16">My Personal Information</h1>
          <ng-container *ngIf="alertType" [ngSwitch]="alertType">
            <rkt-alert *ngSwitchCase="'success'" variant="success">
              <p class="rkt-Alert__text">
                Thank you. Your request has been successfully processed.
              </p>
            </rkt-alert>
            <rkt-alert *ngSwitchCase="'error'" variant="warn">
              <p class="rkt-Alert__text">
                We couldn’t process your request due to an error on our end. Please try again later.
              </p>
            </rkt-alert>
          </ng-container>
              
          <p class="rkt-Body-18 rkt-Spacing--mt24 rkt-Spacing--mb24">
            If you're a California, Connecticut, Oregon, Texas, or Virginia resident, you may request to opt out of the "sale" or "sharing" of your personal information to third parties and Affiliates.
            Although we don't sell your personal information in the traditional sense, we may disclose
            your personal information to third parties and our Affiliates for things like marketing and analytics, among other purposes. This may be 
            considered "selling" under state privacy law. We also may "share" personal information, which means the practice of providing your personal information to third parties and Affiliates for the purpose of
            serving you specific promotions or advertisements that might be interest based on your activity across different websites. <b>To make a request, please provide the following
            information</b> associated with your Rocket Family of Companies interactions. <b>This will
            help us to process your opt-out request.</b> This information is
            only used to process your request and not stored for other purposes.
          </p>
          <a rktLink href="#/privacy-policy">Learn more about your consumer rights</a>.
          <mat-checkbox class="rkt-Checkbox rkt-Spacing--mt24 rkt-Spacing--mb24" (change)="isCaliChanged($event)" [(ngModel)]="caliChecked">I am a California, Connecticut, Oregon, Texas, or Virginia resident and certify that the information I am providing is true and accurate.</mat-checkbox>
              
          <form (submit)="onSubmit()" method="post" [formGroup]="mainForm" id="doNotSellForm" class="center-block">
            <div class="form-flex-box">
              <div id="first-name-parent" class="flex-item">
                <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                  <mat-label for="firstName" >First Name</mat-label>
                  <input matInput 
                  class="rkt-Input" type="text" name="firstName" formControlName="firstName" autocomplete="firstName" id="firstName">
                  <mat-error
                  class="rkt-ErrorText" *ngIf="firstName?.errors?.['required']">Please enter a first name.</mat-error>
                  <mat-error
                  class="rkt-ErrorText" *ngIf="firstName?.errors?.['pattern']">Please enter a valid first name.</mat-error>
                  <mat-error
                  class="rkt-ErrorText" *ngIf="firstName?.errors?.['maxlength']">Cannot be more than 50 characters.</mat-error>
                </mat-form-field>
              </div>
          
              <div id="last-name-parent" class="flex-item">
                <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                  <mat-label for="lastName">Last Name</mat-label>
                  <input matInput
                  class="rkt-Input" type="text" name="lastName" formControlName="lastName" autocomplete="lastName" id="lastName">
                  <mat-error
                  class="rkt-ErrorText" *ngIf="lastName?.errors?.['required']">Please enter a last name.</mat-error>
                  <mat-error
                  class="rkt-ErrorText" *ngIf="lastName?.errors?.['pattern']">Please enter a valid last name.</mat-error>
                  <mat-error
                  class="rkt-ErrorText" *ngIf="lastName?.errors?.['maxlength']">Cannot be more than 50 characters.</mat-error>
                  </mat-form-field>
              </div>
            </div>
  
            <div class="form-flex-box">
              <div id="email-address-parent" class="flex-item">
                <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                  <mat-label for="emailAddress" >Email Address</mat-label>
                  <input matInput 
                  class="rkt-Input" type="text" name="emailAddress" formControlName="emailAddress" autocomplete="emailAddress" id="emailAddress">
                  <mat-error class="rkt-ErrorText" *ngIf="emailAddress?.errors?.['required']">Please enter an email address.</mat-error>
                  <mat-error class="rkt-ErrorText" *ngIf="emailAddress?.errors?.['pattern']">Please enter a valid email address.</mat-error>
                  <mat-error class="rkt-ErrorText" *ngIf="emailAddress?.errors?.['maxlength']">Cannot be more than 128 characters.</mat-error>
                </mat-form-field>
              </div>
  
              <div id="phone-number-parent" class="flex-item">
                <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                  <mat-label for="phonenumber" >Phone Number</mat-label>
                  <input matInput 
                  class="rkt-Input" type="text" name="phoneNumber" formControlName="phoneNumber" autocomplete="phoneNumber" id="phoneNumber">
                  <mat-error class="rkt-ErrorText" *ngIf="phoneNumber?.errors?.['required']">Please enter a phone number.</mat-error>
                  <mat-error class="rkt-ErrorText" *ngIf="phoneNumber?.errors?.['pattern']">Please enter a valid phone number.</mat-error>
                </mat-form-field>
              </div>
            </div>

            <div formGroupName="mailingAddress">
              <div class="form-flex-box">
                <div class="flex-item">
                  <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                    <mat-label for="addressLine1" >Street Address, Line 1</mat-label>
                    <input matInput 
                    class="rkt-Input" type="text" name="addressLine1" formControlName="addressLine1"  autocomplete="addressLine1" id="addressLine1">
                    <mat-error class="rkt-ErrorText" *ngIf="addressLine1?.errors?.['required']">Please enter a street address.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="addressLine1?.errors?.['pattern']">Please enter a valid street address.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="addressLine1?.errors?.['maxlength']">Cannot be more than 255 characters.</mat-error>
                  </mat-form-field>
                </div>
                <div class="flex-item">
                  <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                    <mat-label for="addressLine2" >Street Address, Line 2</mat-label>
                    <input matInput 
                    class="rkt-Input" type="text" name="addressLine2" formControlName="addressLine2"  autocomplete="addressLine2" id="addressLine2">
                    <mat-error class="rkt-ErrorText" *ngIf="addressLine2?.errors?.['pattern']">Please enter a street address.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="addressLine2?.errors?.['maxlength']">Cannot be more than 255 characters.</mat-error>
                  </mat-form-field>
                </div>
              </div>
  
              <div class="form-flex-box">
                <div id="city-parent" class="flex-item">
                  <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                    <mat-label for="city" >City</mat-label>
                    <input  matInput 
                    class="rkt-Input" type="text" name="city" formControlName="city"  autocomplete="city" id="city">
                    <mat-error class="rkt-ErrorText" *ngIf="city?.errors?.['required']">Please enter a city.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="city?.errors?.['pattern']">Please enter a valid city.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="city?.errors?.['minlength']">Cannot be less than 2 characters.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="city?.errors?.['maxlength']">Cannot be more than 50 characters.</mat-error>
                </mat-form-field>
                </div>
    
                <div id="state-parent" class="flex-item rkt-FormField--full-width">
                  <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                    <mat-label for="state" >State</mat-label>
                    <input matInput 
                    class="rkt-Input" type="text" name="state" formControlName="state"  autocomplete="state" id="state">
                    <mat-error class="rkt-ErrorText" *ngIf="state?.errors?.['required']">Please enter a state.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="state?.errors?.['pattern']">Please enter a valid state.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="state?.errors?.['minlength']">Must be 2 characters.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="state?.errors?.['maxlength']">Must be 2 characters.</mat-error>
                  </mat-form-field>
                </div>
                
                <div id="zip-parent" class="flex-item">
                  <mat-form-field appearance="fill" color="accent" class="rkt-FormField rkt-FormField--full-width">
                    <mat-label for="zipCode" >ZIP Code</mat-label>
                    <input matInput 
                    class="rkt-Input" type="text" name="zipCode" formControlName="zipCode"  autocomplete="zipCode" id="zipCode">
                    <mat-error class="rkt-ErrorText" *ngIf="zipCode?.errors?.['required']">Please enter a zip code.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="zipCode?.errors?.['pattern']">Please enter a valid zip code.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="zipCode?.errors?.['minlength']">Must be 5 characters.</mat-error>
                    <mat-error class="rkt-ErrorText" *ngIf="zipCode?.errors?.['maxlength']">Must be 5 characters.</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>

            <div class="form-flex-box">
              <div id="recaptcha-parent" class="flex-item recaptcha">
                <div #recaptcha ></div>
              </div>
              <div>            
                <button type="submit" [ngClass]="{'rkt-Button--is-disabled': !mainForm.valid}" class="rkt-Button rkt-Button--large do-not-sell-submit" id="do-not-sell-submit" routerLink="/create-account" color="primary" 
                target="_blank" [disabled]="!mainForm.valid">
                      Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div
        rktStackItem
        class="rkt-Stack__item--fourth@2 fourth rkt-Stack--center-all"
      ></div>
    </div>
  </rkt-stack>