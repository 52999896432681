<single-column [terms]="false" [talk]="false">
    <h1 class="rkt-TextAlign--center rkt-Heading-36 rkt-Spacing--mb8">Talk to us</h1>
    <h2 class="rkt-TextAlign--center rkt-Body-18">Support for the following companies can be found here.</h2>

    <logo-block></logo-block>

    <div class="rkt-BackgroundColor--gray-100 bgGrey" *ngIf="!ttuFlagIsActive">
        <h3 class="rkt-Heading-20 rkt-Spacing--mb8">Hours of operation</h3>
        <p class="rkt-Body-18"><b>Mon - Fri:</b> 8:30 a.m. – 9:00 p.m. ET<br>
            <b>Sat:</b> 9:00 a.m. - 4:00 p.m. ET</p>

        <h3 class="rkt-Heading-20">Email</h3>
        <p class="rkt-Body-18">Please take a moment to determine which email address best suits your needs.</p>
        <p class="rkt-Body-18">If you have a <b>new loan</b>, a <b>loan in process</b>, are a <b>Rocket Money account holder</b>, or have any
        <b>other concerns</b>, reach out to:</p>
        <p class="rkt-Body-18"><a class="rkt-Body-18" href="mailto:support@rocketaccount.com" rktLink>Support&#64;rocketaccount.com</a></p>
        <p class="rkt-Body-18">If you're currently a <b>serviced client</b> and make <b>monthly mortgage payments</b>, reach out to:</p>
        <p class="rkt-Body-18"><a class="rkt-Body-18" href="mailto:supportservicing@rocketaccount.com" rktLink>SupportServicing&#64;rocketaccount.com</a></p>

        <h3 class="rkt-Heading-20">Toll free</h3>
        <p class="visible-xs rkt-Body-18"><a href="tel:8552079072" rktLink>(855) 207-9072</a></p>
        <p class="hidden-xs rkt-Body-18">(855) 207-9072</p>
    </div>

    <div class="rkt-BackgroundColor--gray-100 bgGrey" *ngIf="ttuFlagIsActive">
        <h3 class="rkt-Heading-20">Hours of operation</h3>
        <p class="rkt-Body-18"><b>Mon - Fri:</b> 8:30 a.m. – 9:00 p.m. ET<br>
            <b>Sat:</b> 9:00 a.m. - 4:00 p.m. ET</p>

        <h3 class="rkt-Heading-20">Email</h3>
        <p class="rkt-Body-18">Please take a moment to determine which email address best suits your needs.</p>
        <p class="rkt-Body-18">If you have a <b>new loan</b>, a <b>loan in process</b>, or have any
        <b>other concerns</b>, reach out to:</p>
        <p class="rkt-Body-18"><a class="rkt-Body-18" href="mailto:support@rocketaccount.com" rktLink>Support&#64;rocketaccount.com</a></p>
        <p class="rkt-Body-18">If you're currently a <b>serviced client</b> and make <b>monthly mortgage payments</b>, reach out to:</p>
        <p class="rkt-Body-18"><a class="rkt-Body-18" href="mailto:supportservicing@rocketaccount.com" rktLink>SupportServicing&#64;rocketaccount.com</a></p>

        <h3 class="rkt-Heading-20">Toll free</h3>
        <p class="visible-xs rkt-Body-18"><a href="tel:8552079072" rktLink>(855) 207-9072</a></p>
        <p class="hidden-xs rkt-Body-18">(855) 207-9072</p>
    </div>

</single-column>
