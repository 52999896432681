import { Component, OnInit } from '@angular/core';
import { LogoScriptService } from 'app/services/generic/logo-script.service';
import { SplitService } from 'app/modules/feature-flags/services/split.service';
import { CommonModule } from '@angular/common';
import { LogoService } from 'app/services/generic/logo-service';
import { environment } from 'environments/environment';

@Component({
  standalone: true,
  selector: 'logo-block',
  templateUrl: './logo-block.component.html',
  styleUrls: ['./logo-block.component.scss'],
  imports: [ CommonModule ]
})
export class LogoBlockComponent implements OnInit {
  public showNewLogo: boolean = false;
  public rocketMortgageLogo: string = '';
  public rocketHomesLogo: string = '';
  public rocketLoansLogo: string = '';
  
  constructor(
    private logoScriptService: LogoScriptService,
    private logoSplitService: LogoService
  ) {
  }

  async ngOnInit() {
    this.rocketMortgageLogo = environment.logoUrls.rocketMortgage;
    this.rocketHomesLogo = environment.logoUrls.rocket; //Rocket Homes is now generally being rebranded under the Rocket Banner
    this.rocketLoansLogo = environment.logoUrls.rocketLoans;
    this.showNewLogo = await this.logoSplitService.getLogoTreatment();
    this.logoScriptService.logoScript(window, document, "body {opacity: 0 !important}");
  }
}
