<div *ngIf="!showNewLogo">
    <!-- Web view -->
    <div class="logos logos-standard logos-standard--first-row" id="standard-logo-block">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo" alt="Rocket Loans">
    </div>
  
    <!-- Mobile view -->
    <div class="logos-mobile--first-row" id="mobile-logo-block">
        <img src="/assets/img/logo/RM-logo.svg" id="rmLogo" alt="Rocket Mortgage">
        <img src="/assets/img/logo/RH-logo.svg" id="rhLogo" alt="Rocket Homes">
        <img src="/assets/img/logo/RL-logo.svg" id="rlLogo"  alt="Rocket Loans">
    </div>
</div>


<!-- NEW Logos -->
<div *ngIf="showNewLogo">
    <!-- Web view -->
    <div class="logos logos-standard logos-standard--first-row-new" id="standard-logo-block">
        <img [src]="rocketMortgageLogo" id="rmLogo-new" alt="Rocket Mortgage">
        <img [src]="rocketHomesLogo" id="rhLogo-new" alt="Rocket Homes">
        <img [src]="rocketLoansLogo" id="rlLogo-new" alt="Rocket Loans">
    </div>
  
    <!-- Mobile view -->
    <div class="logos-mobile--first-row" id="mobile-logo-block">
        <img [src]="rocketMortgageLogo" id="rmLogo-new" alt="Rocket Mortgage">
        <img [src]="rocketHomesLogo" id="rhLogo-new" alt="Rocket Homes">
        <img [src]="rocketLoansLogo" id="rlLogo-new"  alt="Rocket Loans">
    </div>
</div>
  
  