<div *ngIf="!showUpdatedPolicy" class="rkt-CenteredColumn privacy-policy" id="top">
  <div class="marginDiv">
      <!-- 3/5 stack w/privacy policy -->
      <rkt-stack splitAt="6">
          <div rktStackItem class="rkt-Stack__item--three-fifths@6">
            <!-- rocket logo -->
            <img *ngIf="!showNewLogo" class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg">
            <!-- new rocket logo -->
            <img *ngIf="showNewLogo" class="rocket-new-logo" [src]="logoUrl">
              <!-- headers and copy -->
              <rkt-stack spacing="8">
                  <div rktStackItem id="rocket-privacy-policy" class="header-title">
                      <h1 class="rkt-Heading-28 rkt-Spacing--mb24">
                        Política de privacidad del grupo de empresas Rocket
                      </h1>
                      <!-- print button, shows only at small viewports (under 880px) -->
                      <div class="print-button--container print-button--narrow" *ngIf="showTableOfContents">
                          <button class="rkt-Button rkt-Button--secondary" mat-stroked-button color="accent"
                              id="print-button" (click)="printPrivacyPolicy()">Imprimir politica de privacidad</button>
                      </div>
                      <p class="rkt-Body-16 rkt-Spacing--mb24"><b>Fecha de vigencia</b>: 8 de enero de 2024</p>
                      <p class="rkt-Body-16 rkt-Spacing--mb24">
                        Esta Política de privacidad del grupo de empresas Rocket describe cómo el grupo de empresas Rocket (como se define en  
                        <a rktLink href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a> abajo) procesa la información recopilada en nuestros sitios web y 
                        aplicaciones (incluyendo los dispositivos móviles) o por medio de sus interacciones en línea con nosotros (colectivamente, nuestros “Servicios”).
                      </p>
                      <p class="rkt-Body-16 rkt-Spacing--mb24">
                        En algunos casos, es posible que le enviemos otros avisos complementarios o de privacidad en la medida en que se apliquen a usted por los 
                        productos y servicios que obtiene de nosotros o las diferentes leyes que se apliquen en su caso. 
                        Es posible que algunos estados apliquen derechos de privacidad extras. Consulte la sección
                        <a rktLink href="#/privacy-policy-spanish#ca-privacy-policy">Información extra sobre sus derechos de privacidad estatales para obtener más información.</a>.
                      </p>
                      <p class="rkt-Body-16 rkt-Spacing--mb24">
                          <b>
                            Cuando usa cualquiera de nuestros servicios, reconoce las prácticas de privacidad que describimos en esta Política de privacidad del grupo de empresas Rocket. 
                            También reconoce que leyó esta política y otras políticas descritas. </b>
                      </p>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                      class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Who We Are" section -->
                  <div rktStackItem id="who-we-are">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb24">Quiénes somos</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb24">
                        Cuando decimos "<b>el grupo de empresas Rocket</b>" nos referimos a empresas que controla RKT Holdings, LLC y que comparten el nombre 
                        Rocket o que están en la plataforma de Rocket. Cuando usamos Rocket nosotros o nos nos referimos a la empresa Rocket relevante, 
                        en el grupo de empresas Rocket, implicada en la actividad del procesamiento. Esta política no se aplica a los afiliados de Rocket que no 
                        son parte del grupo de empresas Rocket (“Afiliados”).Las prácticas sobre datos de los Afiliados se rigen por sus propias políticas de privacidad.
                      </p>
                      <div rktStackItem>
                          <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider>
                          </div>
                      </div>
                      <mat-accordion class="rkt-Spacing--mb24">
                          <mat-expansion-panel class="rkt-AccordionPanel rkt-Spacing--mb24"
                              idString="rocket-family-of-companies" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Grupo de empresas Rocket</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div class="rkt-AccordionPanel__content">
                                  <p class="rkt-Body-14 rkt-Spacing--mb8">El grupo de empresas Rocket incluye:</p>
                                  <ul>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Mortgage</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Loans</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Homes</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Money, Inc. (conocida antes como Truebill, Inc.) (“Rocket Money”), una empresa Rocket</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Central</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">ForSaleByOwner.com, una empresa Rocket</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Card</p>
                                      </li>
                                  </ul>
                              </div>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Information We Collect" section -->
                  <div rktStackItem id="information-we-collect">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Información que recopilamos</h1>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="information-you-provide"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que usted da</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">Recopilamos la información que usted nos da. Por ejemplo, recopilamos su información cuando crea una cuenta de Rocket, completa un formulario o envía 
                                contenido por medio de nuestros Servicios; nos los refiere uno de nuestros colaboradores profesionales externos; hace una compra; se comunica con nosotros 
                                mediante plataformas de terceros; participa en un concurso, promoción o encuesta; pide información sobre nuestros Servicios, o se comunica con 
                                nosotros de cualquier otra manera. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                La información específica que recopilamos depende del contexto en que usted nos la dé, y podría incluir:
                              </p>
                              <ul>
                                <li>Nombre</li>
                                <li>Dirección de correo electrónico</li>
                                <li>Dirección postal</li>
                                <li>Teléfono</li>
                                <li>Número del Seguro Social</li>
                                <li>Ubicación y valor aproximado de la propiedad</li>
                                <li>Información del auto, como marca, modelo, VIN y características</li>
                                <li>Edad o fecha de nacimiento</li>
                                <li>Información demográfica, como raza, origen étnico y sexo recopilada según requisitos federales</li>
                                <li>Productos o servicios de préstamos relacionados que haya usado en el pasado</li>
                                <li>Información financiera, como ingresos, activos y patrimonio neto</li>
                                <li>Estado de la propiedad de la vivienda y estado de empleo</li>
                                <li>Información del seguro</li>
                                <li>Otros datos de perfil, incluyendo sus intereses y preferencias</li>
                                <li>Cualquier otra información que usted decida darnos</li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="information-we-automatically-collect" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que recopilamos automáticamente</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">Recopilamos automáticamente cierta información sobre sus interacciones con nosotros o nuestros Servicios, incluyendo:</p>
                              <ul>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información de transacciones: </b>cuando hace una compra o completa una transacción, recopilamos información sobre la transacción, 
                                        como información del producto o servicio, información económica, fecha y lugar de la transacción.
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información del dispositivo y del: </b>recopilamos información sobre cómo accede a nuestros Servicios, incluyendo los datos sobre el dispositivo y la red que 
                                        usa, como el modelo de hardware, la versión del sistema operativo, red móvil, dirección IP, identificadores únicos del dispositivo, configuración regional y de idioma del 
                                        dispositivo, tipo de navegador y versión de la aplicación. También recopilamos información sobre su actividad en nuestros Servicios, como tiempos de acceso, páginas 
                                        visitadas, enlaces en los que hizo clic, productos y servicios considerados y la página que visitó antes de navegar a nuestros Servicios.
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Su contenido: </b>según usted lo permita mediante su dispositivo o aplicación, podríamos recopilar información y contenido almacenados en su dispositivo, como fotos.
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información de la ubicación: </b>según los permisos de su dispositivo, podríamos recopilar información sobre el lugar exacto de su dispositivo. Usted puede detener 
                                        la recopilación de información del lugar exacto en cualquier momento (para obtener más información consulte abajo la sección de
                                        <a rktLink href="#/privacy-policy-spanish#your-choices">Sus opciones</a> ).
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información recopilada por <i>cookies</i> y tecnologías de seguimiento similares: </b> nosotros (y los que hacen trabajos para nosotros) usamos tecnologías 
                                        de seguimiento, como cookies y balizas web, para recopilar información sobre sus interacciones con los Servicios.
                                      </p> 
                                      <ul>
                                          <li>
                                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                Las <b>cookies</b> son pequeños archivos de datos almacenados en su disco duro o en la memoria del dispositivo que nos ayudan a mejorar nuestros Servicios
                                                y su experiencia, ver qué áreas y características de nuestros Servicios son populares y los correos electrónicos y anuncios que usted ve, autenticar 
                                                a los usuarios, reconstruir u observar la actividad de una sesión o la de un usuario para la solución de problemas, resolver problemas, y otros propósitos, y
                                                contar las visitas a nuestros sitios web.
                                              </p>
                                          </li>
                                          <li>
                                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                Las <b>balizas web</b> (también conocidas como <b>etiquetas de píxeles</b> o <b>GIF transparentes</b>) son imágenes electrónicas que usamos en nuestros
                                                Servicios y en nuestros correos electrónicos para enviar <i>cookies</i>, contar visitas y entender el uso y la eficacia de la campaña. En nuestras 
                                                aplicaciones móviles, también podríamos usar tecnologías que no están basadas en el navegador como las cookies.
                                              </p>
                                          </li>
                                          <li>
                                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                Por ejemplo, nuestras aplicaciones pueden incluir <b>kits de desarrollo de software</b> (conocidos como SDK), que son un código que envía a un servidor 
                                                información sobre el uso que usted les da. Estos SDK nos permiten dar seguimiento a nuestras conversiones, mostrarle publicidad dentro y fuera del 
                                                Servicio y darle otras funciones.
                                                <b>Para obtener más información sobre estas tecnologías de seguimiento y cómo deshabilitarlas, 
                                                  consulte la sección <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a> abajo.</b>
                                              </p>
                                          </li>
                                      </ul>
                                  </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="information-we-collect"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que recopilamos de otras fuentes</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Obtenemos información de fuentes externas en ciertas situaciones. Por ejemplo, podríamos recopilar información sobre usted de servicios de verificación 
                                de identidad u otros servicios de verificación, agencias de crédito (incluyendo su informe de crédito), redes de publicidad, proveedores de listas de
                                distribución de correo, proveedores de servicios y corredores de datos. Nuestros Servicios también podrían permitirle o pedirle que integre o conecte su 
                                cuenta Rocket con servicios de terceros por medio de proveedores de servicios (como Plaid).
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                La información que recibimos de esos terceros varía dependiendo de la información que esas entidades faciliten. Algunos ejemplos
                                de los tipos de información que recibimos incluyen información de cuentas financieras, de saldos de las cuentas, de cuentas de crédito, 
                                de cuentas de préstamos, de cuentas de inversión, identificadores e información sobre propietarios de cuentas, de transacciones de cuentas e información laboral.
                              </p>

                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Además, si usted crea o inicia sesión en su cuenta Rocket usando una plataforma de terceros, como Facebook o Google, tendremos acceso a cierta 
                                información suya en esa plataforma, como su nombre, fecha de nacimiento y foto de perfil, de acuerdo con los procedimientos de autorización de la plataforma. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="information-we-derive"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que deducimos</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Podemos deducir información o hacer inferencias sobre usted según la información que recopilamos. Por ejemplo, podemos hacer inferencias
                                sobre su ubicación según su dirección IP, ciudad o código postal, o que está buscando comprar ciertos productos basándonos en la 
                                información que nos da, su historial de navegación y las compras que haya hecho. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="plaid-technologies"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Plaid Technologies</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Rocket le permite usar Plaid Technologies, Inc. para recopilar datos de instituciones financieras. Cuando usa los Servicios, usted reconoce 
                                y acepta que la información se procesará de acuerdo con la
                                <a rktLink href="https://plaid.com/legal/#consumers" target="blank">Política de privacidad de Plaid,</a> y usted les da a Rocket y a Plaid los
                                mismos derechos, facultades y autoridad que se especifican en esa política.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "How We Use Your Information" section -->
                  <div rktStackItem id="how-we-use">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Cómo usamos su información</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">Usamos la información que recopilamos para prestarle nuestros Servicios.</p>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="other-reasons-to-collect"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Otros motivos para recopilar información</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                También la usamos para:
                              </p>
                              <ul>
                                  <li>
                                    Cumplir o prestar los servicios que usted haya pedido
                                  </li>
                                  <li>
                                    Ofrecer, mantener, mejorar y crear nuevos productos, servicios y características, incluyendo la depuración y reparación de errores en nuestros
                                    Servicios
                                  </li>
                                  <li>
                                    Personalizar su experiencia con nosotros
                                  </li>
                                  <li>
                                    Enviarle avisos técnicos, alertas de seguridad, mensajes de soporte y otros mensajes sobre las transacciones o la relación comercial, incluyendo
                                    respuestas a sus consultas y comentarios
                                  </li>
                                  <li>
                                    Comunicarnos con usted sobre productos, servicios y eventos que ofrecen Rocket y otros, y enviarle noticias e información que creamos que 
                                    le interesarían. <b> <a rktLink href="#/privacy-policy-spanish#your-choices">Para</a> obtener información sobre cómo 
                                    dejar de recibir estas comunicaciones en cualquier momento consulte abajo la sección Sus opciones.</b>
                                  </li>
                                  <li>
                                    Monitorear y analizar tendencias, uso y actividades relacionadas con nuestros productos y servicios
                                  </li>
                                  <li>
                                    Personalizar los anuncios que ve en plataformas y sitios web de terceros. <b>Para obtener más información, consulte abajo la sección 
                                      <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis. </a></b>
                                  </li>
                                  <li>
                                    Mostrarle ofertas de servicios de terceros personalizados para usted, según la información suya que hayamos recopilado
                                  </li>
                                  <li>
                                    Facilitar concursos, sorteos y promociones, y procesar y entregar entradas y premios
                                  </li>
                                  <li>
                                    Detectar, investigar y ayudar a prevenir incidentes de seguridad y otras actividades maliciosas, engañosas, fraudulentas o 
                                    ilegales, y ayudar a proteger los derechos y la propiedad de Rocket y de otros
                                  </li>
                                  <li>
                                    Cumplir nuestras obligaciones legales y financierasComply with our legal and financial obligations
                                  </li>
                                  <li>
                                    Crear información sin identificación, anónima o agrupada
                                  </li>
                                  <li>
                                    Cumplir cualquier otro propósito bajo su dirección.
                                  </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "How We Share Your Information" section -->
                  <div rktStackItem id="how-we-share">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Cómo compartimos su información</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Compartimos información personal en las siguientes circunstancias o como se describe en esta política.
                      </p>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="sharing-among-rocket-family-of-companies" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Cómo la compartimos entre el grupo de empresas Rocket</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>El grupo de empresas Rocket</b> usa y comparte su información internamente según esta 
                                <b>política de privacidad del grupo de empresas Rocket</b>. Esto le permite <b>al grupo de empresas Rocket</b> administrar, prestar y
                                ofrecerle varios de nuestros servicios durante el amplio ciclo de vida de sus necesidades personales y financieras.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="sharing-with-affiliates"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Cómo la compartimos con Afiliados</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Rocket también comparte su información con nuestros Afiliados. Por ejemplo, compartimos, o podemos compartir, su información:
                              </p>
                              <ul>
                                  <li>
                                    Con Afiliados que actúan como proveedores, prestadores de servicios y consultores que acceden a información personal para prestar servicios por nosotros, como
                                    las empresas que nos ayudan con los servicios de call center/directorio
                                  </li>
                                  <li>
                                    Con Afiliados para sus propios servicios y marketing
                                  </li>
                                  <li>
                                    Con Afiliados para fines de informes para agencias reguladoras y registro de valores medibles del rendimiento
                                  </li>
                                  <li>
                                    Con Afiliados si creemos (1) que sus acciones son incongruentes con nuestros acuerdos o políticas del usuario, (2) que usted ha infringido
                                    la ley o (3) que es necesario para proteger los derechos, la propiedad y la seguridad de Rocket, nuestros usuarios, el público u otros                    
                                  </li>
                                  <li>
                                    En relación con, o durante las negociaciones relacionadas a cualquier fusión, venta de activos de la empresa, financiamiento o 
                                    adquisición de todo o de una parte de nuestro negocio por otra empresa.
                                  </li>
                                  <li>
                                    Con Afiliados con su consentimiento o por instrucciones suyas
                                  </li>
                              </ul>
                              <br>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                También compartimos información agrupada o sin identificación que no se pueda usar razonablemente para identificarlo.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="sharing-with-other-third-parties"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Cómo la compartimos con terceros</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Rocket comparte su información con terceros no afiliados en las siguientes circunstancias:
                              </p>
                              <ul>
                                  <li>
                                    Compartimos información personal con proveedores de productos y servicios y con consultores que acceden a información personal 
                                    para prestarnos servicios, como empresas que nos ayudan con alojamiento web, envío y entrega, procesamiento
                                    de pagos, prevención del fraude, servicio de atención al cliente, análisis de datos, títulos de propiedad y servicios de tasación, soporte técnico, mercadeo y publicidad.                    
                                  </li>
                                  <li>
                                    Si usted envía una reseña de algún producto o publica contenido en otra área pública de nuestros Servicios, compartimos esa información 
                                    públicamente en nuestros Servicios.
                                  </li>
                                  <li>
                                    Si usted elige usar las integraciones que ofrecemos en nuestros Servicios, como compartir su ubicación por medio de nuestra integración con Google 
                                    Maps, podríamos compartir cierta información con los colaboradores de la integración. 
                                  </li>
                                  <li>
                                    Compartimos información personal con terceros para los propios servicios de ellos y para fines de mercadeo, análisis y, en algunos casos, para comercializar
                                    y ofrecer productos y servicios junto con terceros.
                                  </li>
                                  <li>
                                    Cuando usted pide información sobre los servicios que ofrecen otras empresas, podríamos revelar información sobre usted a esas empresas para que nosotros o
                                    ellas podamos enviarle avisos y determinar si usted califica para recibir productos o servicios.
                                  </li>
                                  <li>
                                    Podríamos revelar información personal si creemos que la revelación se hace según cualquier ley o proceso legal aplicable, o si así lo 
                                    exigen las leyes o procesos legales, incluyendo solicitudes legales de las autoridades para cumplir los requisitos de seguridad nacional o de aplicación de la ley.
                                  </li>
                                  <li>
                                    Podríamos compartir información personal si creemos (1) que sus acciones son incongruentes con nuestros acuerdos o políticas del usuario, 
                                    (2) que usted ha infringido la ley o (3) que es necesario para proteger los derechos, la propiedad y la seguridad de Rocket, nuestros usuarios, 
                                    el público u otros.
                                  </li>
                                  <li>
                                    Compartimos información personal con nuestros abogados y otros asesores profesionales cuando es necesario para recibir asesoría o 
                                    proteger y administrar nuestros intereses comerciales.
                                  </li>
                                  <li>
                                    Podríamos compartir información personal en relación con, o durante las negociaciones relacionadas con cualquier fusión, venta de activos 
                                    de la empresa, financiamiento o adquisición de todo o de una parte de nuestro negocio por otra empresa.
                                  </li>
                                  <li>
                                    Compartimos información personal con su consentimiento o por instrucciones suyas incluyendo, entre otros, 
                                    mediante integraciones de terceros que usted elija habilitar.
                                  </li>
                                  <li>
                                    También compartimos información agrupada o sin identificación que no se pueda usar razonablemente para identificarlo.
                                  </li>
                                  <li>
                                    Si usted se suscribió a un programa de ofertas de códigos cortos de SMS, sus datos de suscripción y su consentimiento para el programa de 
                                    ofertas no se compartirá con terceros. Si dio su consentimiento por separado para que lo contacten (fuera del programa de ofertas de 
                                    códigos cortos de SMS), esa información se puede compartir. 
                                  </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Advertising and Analytics" section -->
                  <div rktStackItem id="advertising-and-analytics">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Publicidad y análisis</h1>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="web-and-mobile-apps"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Web y aplicaciones móviles</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Compartimos información personal para permitir que otros presten servicios de análisis y publiquen anuncios en la web y en aplicaciones 
                                móviles. Estas entidades pueden usar <b>cookies, balizas web, identificadores de dispositivos</b> y otras tecnologías para recopilar 
                                información sobre cómo usa usted nuestros Servicios y otros sitios web y aplicaciones, incluyendo su dirección IP, navegador web, información 
                                de redes móviles, páginas vistas, tiempo en las páginas o aplicaciones móviles, enlaces en los que hizo clic e información de conversión.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Esta información la pueden usar Rocket y otros para, entre otras cosas, analizar y rastrear datos, reconstruir u observar la actividad 
                                relacionada con una sesión, determinar la popularidad de cierto contenido, mostrar publicidad y contenido según sus intereses en 
                                nuestros Servicios y otros sitios web, y comprender mejor su actividad en línea.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>
                                  Para obtener más información sobre los anuncios según sus intereses, o para que se deje de usar su información de navegación web con fines 
                                  de publicidad conductual, visite <a rktLink href="http://www.aboutads.info/choices" target="blank">www.aboutads.info/choices</a>. 
                                </b>
                                Es posible que su dispositivo también incluya una función (<i>“Limit Ad Tracking”</i> [Limitar el seguimiento de anuncios] en iOS, u 
                                <i>“Opt Out of Interest-Based Ads”</i> [Dejar de recibir anuncios según mis intereses] u <i>“Opt Out of Ads Personalization”</i> 
                                [Dejar de personalizar mis anuncios] en Android) que le permite evitar que se recopile cierta información mediante aplicaciones móviles y que se usa 
                                con fines de publicidad conductual. Las leyes de privacidad de algunos estados también les dan a sus residentes derechos relacionados a la exclusión 
                                voluntaria directa del intercambio de información personal con fines de publicidad basada en el contexto o en intereses. 
                                Para obtener más información, consulte abajo la sección Otra información sobre sus derechos de privacidad estatales.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="third-parties" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Terceros</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                También compartimos información personal para trabajar con terceros para mostrarle anuncios como parte de campañas personalizadas en plataformas 
                                de terceros (como Facebook). Como parte de esas campañas publicitarias, nosotros o las plataformas de terceros podríamos convertir información sobre usted, como 
                                la dirección de correo electrónico o el teléfono, en un valor único que se puede combinar con una cuenta de usuario en esas plataformas para que podamos conocer 
                                sus intereses y ofrecerle publicidad personalizada según sus intereses. Tenga en cuenta que las plataformas de terceros pueden darle la opción de ver o no este 
                                tipo de anuncios personalizados.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Children's Privacy" section -->
                  <div rktStackItem id="childrens-privacy">
                    <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Privacidad de los niños</h1>
                    <p class="rkt-Body-14 rkt-Spacing--mb8">
                      Los productos Rocket no están destinados para niños. No dirigimos nuestros Servicios a niños menores de 16 años, ni intentamos pedir ni recibir de manera intencional 
                      información de niños.
                    </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "California Shine the Light" section -->
                  <div rktStackItem id="california-shine-the-light">
                    <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Ley Shine The Light de California</h1>
                    <p class="rkt-Body-14 rkt-Spacing--mb8">
                      La ley de California les permite a los residentes de California pedir cierta información sobre cómo se comparten sus datos con terceros con fines 
                      de marketing directo. Si es residente de California y quiere pedir esa información, llámenos al <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                    </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Processing of Information" section -->
                  <div rktStackItem id="processing-of-information">
                    <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Procesamiento de la información en los Estados Unidos y otros países</h1>
                    <p class="rkt-Body-14 rkt-Spacing--mb8">
                      Rocket tiene su sede en los Estados Unidos y el grupo de empresas Rocket ofrece bienes y servicios a consumidores en los Estados Unidos.
                      Tenemos operaciones y proveedores en los Estados Unidos y otros países. Por lo tanto, nosotros y los que prestan servicios por nosotros, podemos 
                      transferir, almacenar o acceder a su información personal en jurisdicciones que podrían no tener niveles de protección de datos que sean equivalentes 
                      a los de la jurisdicción donde vive.
                    </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Your Choices" section -->
                  <div rktStackItem id="your-choices">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Sus opciones</h1>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="right-to-correct-account-information" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a corregir la información de la cuenta</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Usted puede actualizar y corregir cierta información de la cuenta, o eliminar la cuenta, iniciando sesión o llamándonos al
                                <a rktLink href="tel:888-444-7492">888-444-7492</a>. Tenga en cuenta que podríamos retener cierta información según lo exija la 
                                ley o para nuestros fines comerciales legítimos. Para obtener más información, consulte la sección Otra información sobre 
                                sus derechos de privacidad estatales.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="location-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información de la ubicación</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Cuando inicie por primera vez cualquiera de nuestras aplicaciones móviles que recopilan información de ubicación exacta, o cuando visite 
                                partes de nuestros Servicios que recopilan información de ubicación exacta,se le pedirá que dé su consentimiento para que la aplicación 
                                recopile esta información. Si inicialmente autoriza que recopilemos la información de la ubicación, después puede detener la recopilación de esa 
                                información en cualquier momento cambiando las preferencias en su dispositivo móvil. Es importante mencionar que es posible que algunos Servicios 
                                no funcionen sin la información de ubicación exacta. También puede bloquear una aplicación móvil para que ya no obtenga información de ubicación 
                                siguiendo el proceso de desinstalación estándar para eliminar la aplicación de su dispositivo. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="do-not-track-signals"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Señales para evitar el rastreo</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Es posible que esté usando un navegador de Internet que pueda comunicar sus preferencias de privacidad al sitio web, incluyendo solicitudes de no 
                                rastrear su historial de uso y navegación. Nuestros sitios web están configurados para reconocer esas señales. Si reconocemos una de estas señales —que 
                                su navegador puede denominar o etiquetar como señales de “no rastrear” o “preferencia de exclusión voluntaria” o “preferencia global”—, le damos la opción 
                                de enviar una solicitud de exclusión voluntaria de la venta/compartición. Para obtener más información sobre excluirse de la venta/compartir información 
                                personal, consulte abajo la sección Otra información sobre sus derechos de privacidad estatales.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>

                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Changes To This Policy" section -->
                  <div rktStackItem id="changes-to-this-policy">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Cambios a esta política</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Podemos cambiar esta <b>política de privacidad del grupo de empresas Rocket</b> cada cierto tiempo. Si hacemos cambios, se lo avisaremos 
                        cambiando la fecha de la parte superior de esta política y, en algunos casos, podríamos decírselo de otra manera, como publicando una 
                        declaración en nuestro sitio web o enviándole un aviso. Le recomendamos que revise esta política con regularidad para mantenerse informado 
                        sobre nuestras prácticas de información y las opciones que tiene disponibles.
                      </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <div rktStackItem id="contact-information">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Información de contacto</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Si tiene preguntas sobre esta <b>Política de privacidad del grupo de empresas Rocket</b>, nuestras prácticas de información u otros temas 
                        de privacidad relacionados con nuestros Servicios, llámenos al <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                      </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Additional Information" section -->
                  <div rktStackItem id="additional-information">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">OTRA INFORMACIÓN SOBRE SUS DERECHOS DE PRIVACIDAD ESTATALES</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Ciertos estados, exigen que demos más información sobre nuestras prácticas de privacidad y sus derechos de privacidad. Los 
                        estados , que han aprobado leyes de privacidad amplias, que les dan derechos de privacidad específicos a los residentes, 
                        incluyen: California, Colorado, Connecticut, Delaware, Indiana, Iowa, Montana, Oregón, Tennessee, Texas, Utah y Virginia.  
                        Abajo le damos más avisos sobre nuestras prácticas de privacidad y los derechos del consumidor relacionados con su información. 
                        Algunos derechos pueden depender de la fecha de vigencia de la ley y de si usted es residente de un estado con leyes de privacidad 
                        específicas, y de otros factores. Además, algunas de las empresas del grupo de empresas Rocket son instituciones financieras sujetas 
                        a otros marcos reglamentarios de privacidad como la Ley Gramm Leach Bliley (GLBA), y es posible que no se apliquen las revelaciones de 
                        privacidad ni los derechos descritos en esta sección. 
                      </p>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="notice-of-information-collected"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Aviso de información recopilada</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En los 12 meses anteriores, hemos recopilado las siguientes categorías de información personal:
                              </p>
                              <table mat-table [dataSource]="InfoCollectedTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                  <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categoría</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.category }}
                                      </td>
                                  </ng-container>

                                  <ng-container matColumnDef="detail">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Ejemplos</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.detail }}
                                      </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                  <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                              </table>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>Para obtener más información sobre los puntos de datos precisos que recopilamos y las categorías de fuentes de dicha recopilación, consulte la sección
                                <a rktLink href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>.</b> Recopilamos información personal para los fines comerciales 
                                descritos en la sección <a rktLink href="#/privacy-policy-spanish#how-we-use">Cómo usamos la información</a>.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="disclosure-of-personal-information" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Revelación de la información personal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En los 12 meses anteriores, hemos revelado las siguientes categorías de información personal con fines comerciales a las siguientes 
                                categorías de destinatarios:
                              </p>
                              <table mat-table [dataSource]="InfoSentTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                  <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categoría de la información personal</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.category }}
                                      </td>
                                  </ng-container>

                                  <ng-container matColumnDef="detail">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categorías de los destinatarios</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.detail }}
                                      </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                  <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                              </table>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="right-to-limit-processing-of-sensitive-personal-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a limitar el procesamiento de información personal sensible</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En California, usted tiene derecho a limitar nuestro procesamiento de su información personal sensible solo a los usos que sean necesarios 
                                para prestar los servicios o dar los bienes que razonablemente espera un consumidor promedio que pide dichos bienes o servicios. Cuando 
                                recopilamos su información personal sensible, la recopilamos y usamos para prestar los servicios o dar bienes para los 
                                que es necesaria y según lo que usted espera razonablemente.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Según otras leyes de privacidad estatales, solo se nos permite recopilar ciertos elementos de información personal sensible después de haber 
                                obtenido su consentimiento para hacerlo. Cuando sea necesario, obtendremos su consentimiento para recopilar y usar información personal sensible.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="sale-of-personal-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Venta de la información personal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Las leyes de privacidad estatales exigen ciertas revelaciones para las empresas que “venden” información 
                                personal según la ley de privacidad del estado correspondiente. Cada estado define la “venta” de datos de manera diferente. En California, 
                                la “venta” de datos significa ciertas circunstancias en las que Rocket ha compartido información personal con terceros o Afiliados a 
                                cambio de una contraprestación valiosa. Otros estados definen la “venta” de datos como el intercambio de 
                                información personal de Rocket por un valor monetario con un tercero no afiliado. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Según esta definición, no “vendemos” su información personal.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Según la definición de California, “vendemos” información personal para permitir que nuestros Afiliados y ciertos terceros usen su 
                                información según sus políticas de privacidad. “Vendemos” las siguientes categorías de información personal a las siguientes categorías 
                                de terceros: 
                              </p>
                              <table mat-table [dataSource]="SaleOfPersonalInfoTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                  <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categoría de la información personal</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.category }}
                                      </td>
                                  </ng-container>

                                  <ng-container matColumnDef="detail">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categories of Third Parties</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.detail }}
                                      </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                  <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                              </table>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="right-to-opt-out-of-selling"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a no participar en la venta</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Usted tiene el derecho de dejar de participar en la “venta” descrita arriba, en cualquier momento, visitando nuestra página web 
                                <a rktLink href="#/es/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information (No vendan mi información personal)</a>.
                                No vendemos ni compartimos de manera intencional información personal de consumidores menores de 16 años. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="right-to-opt-out-of-personal-information" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a no participar en el intercambio de información que se comparte o usa en la publicidad dirigida</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                California exige que le informemos si “compartimos” su información personal con terceros. En California, “compartir” significa que ponemos 
                                a disponibilidad o revelamos su información a terceros para publicidad conductual de contexto cruzado. Además “publicidad conductual de 
                                contexto cruzado” significa la publicidad dirigida a usted basándose en la información personal obtenida de su actividad en negocios, sitios 
                                web, aplicaciones y servicios distintos de nuestros Servicios. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                De manera similar, las leyes de privacidad de otros estados nos exigen que informemos si usamos su información para 
                                publicidad dirigida. Publicidad dirigida significa mostrarle anuncios seleccionados basándonos en la información personal obtenida de sus 
                                actividades con el tiempo y en sitios web no afiliados o aplicaciones en línea para predecir sus preferencias o intereses. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En consecuencia, “compartimos” su información personal con terceros con fines de publicidad contextual cruzada, y la usamos para publicidad dirigida. 
                                Específicamente, compartimos sus identificadores y su actividad en Internet u otras redes con nuestros colaboradores publicitarios. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>Usted tiene derecho a optar por no “compartir” ni recibir publicidad dirigida accediendo a nuestro sitio web 
                                  <a rktLink href="#/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information (No vendan mi información personal)</a>.
                                  También respetamos las señales de “No rastrear” del navegador, como mencionamos arriba.</b>
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="retention-of-personal-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Retención de la información personal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                No retendremos su información personal por más tiempo del razonablemente necesario para cumplir los propósitos que revelamos en esta política. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider "></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="state-consumer-data-rights"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derechos de información del consumidor a nivel estatal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Sujeto a ciertas limitaciones, usted tiene derecho a (1) pedir más información sobre las categorías y piezas específicas de información personal 
                                que recopilamos, usamos, revelamos y vendemos, y para acceder a su información, (2) pedir que se elimine su información personal, (3) pedir 
                                la corrección de su información personal y (4) no ser discriminado por ejercer estos derechos. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>Usted puede hacer estas solicitudes 
                                  <a rktLink href="https://www.rocketmortgage.com/privacy-rights/data-request?qls=QRD_12345678.0123456789" target="blank">
                                    presentando una solicitud en línea</a> o llamando al <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                                </b> 
                                Verificaremos su solicitud pidiéndole que nos dé información relacionada con sus interacciones recientes con nosotros.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Si recibimos su solicitud de un agente autorizado, podemos pedirle evidencia de que usted le dio a ese agente un poder, o que el agente 
                                tiene autoridad válida por escrito para presentar solicitudes para ejercer derechos en su nombre. 
                                <b>Si usted es un agente autorizado que quiere hacer una solicitud, comuníquese con nosotros como se menciona arriba.</b>
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="ca-financial-incentive-disclosure"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Revelación de incentivos económicos de California</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                La ley de privacidad de California exige que le informemos si le damos un programa, beneficio u otra oferta relacionada con la recopilación 
                                eliminación o venta de información personal, que se define como un “incentivo económico”. Podemos ofrecerle ciertos incentivos económicos que 
                                pueden dar lugar a diferentes precios, tasas o niveles de calidad (por ejemplo, concursos y programas de remisiones). Por medio de estas 
                                ofertas, los consumidores nos dan cierta información personal (como nombres, correos electrónicos, teléfonos) cuando deciden participar en 
                                nuestros programas. No hay obligación de participar y los consumidores pueden decidir dejar de hacerlo en cualquier momento. La información 
                                de cada programa está en la oferta del programa. Ofrecemos estos programas, entre otros, para mejorar nuestra relación con usted de manera 
                                que pueda disfrutar más de nuestros productos/servicios a un precio más bajo. Invertimos mucho en nuestro marketing y nuestras marcas, en 
                                parte para que podamos ofrecer programas a nuestros clientes. Los datos del consumidor son valiosos para nuestro negocio solo cuando se 
                                combinan con una cantidad suficiente de otros datos del consumidor y después de que los mejoramos con nuestras iniciativas aquí descritas. 
                                El valor para nuestro negocio de la información personal de cualquier consumidor individual depende de una serie de factores incluyendo, 
                                por ejemplo, si aprovecha cualquier oferta y en qué medida lo hace, si decide dejar de participar en alguna oferta, y en qué medida, y si 
                                podemos mejorar los datos mediante nuestras iniciativas aquí descritas. Nuestra capacidad para crear cualquier valor a partir de los 
                                programas se basa en gran medida en nuestra capacidad para aprovechar esas propiedades intelectuales. No calculamos el valor de los datos 
                                de los consumidores en nuestra contabilidad.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="ca-verifiable-client-requests"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Solicitudes de datos del consumidor en California</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En 2023, recibimos 421 solicitudes de datos del consumidor de consumidores de California. Esto incluye solicitudes de personas para saber, acceder o eliminar sus datos.
                                También recibimos 48,406 solicitudes que prefirieron no compartir información.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Este es un resumen de esas solicitudes de los consumidores:
                                <br>
                                <b>119</b> solicitudes para saber/acceder
                                <br>
                                <b>302</b> solicitudes para eliminar
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">Nos ha llevado un promedio de 2 días procesar y responder a las solicitudes.</p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link margin--bottom" href="#/privacy-policy-spanish#top">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Federal Privacy Policy section -->
                  <div rktStackItem id="federal-privacy-notice">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Aviso de privacidad federal</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Algunas empresas del grupo de empresas Rocket deben hacer un Aviso de privacidad extra según la Ley Gramm-Leach-Bliley Act. 
                        Para su conveniencia, abajo incluimos estos avisos. 
                      </p>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                          <a rktLink href="https://static.rocketloans.com/disclosures/our-privacy-notice.pdf"
                              target="blank">Rocket Loans</a>
                          <br>
                          <a rktLink href="https://www.rocketmoney.com/privacy-notice" target="blank">Rocket Money</a>
                          <br>
                          <a rktLink href="/assets/pdf/ServicingPrivacyPolicyV2.pdf" target="blank">Rocket
                              Mortgage</a>
                          <br>
                          <a rktLink href="https://www.rocketcard.com/privacy-notice" target="blank">Rocket Card</a>
                      </p>
                      <a rktLink class="top-link margin--bottom" href="#/privacy-policy-spanish#top">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"></div>
              </rkt-stack>
          </div>
          <!-- 2/5 stack w/table of contents -->
          <div rktStackItem class="rkt-Stack__item--two-fifths@6 rightColumn">
              <!-- print button -->
              <div class="print-button--container print-button--wide">
                  <button class="rkt-Button rkt-Button--secondary" mat-stroked-button color="accent" id="print-button"
                      (click)="printPrivacyPolicy()">Imprimir politica de privacidad</button>
              </div>
              <!-- table of contents -->
              <mat-card idString="default" mat-card class="rkt-Card table-of-contents--wide rkt-Card--white">
                  <h3 class="rkt-Heading-40 rkt-Spacing--mb16">
                    Índice
                  </h3>
                  <rkt-stack spacing="8">
                      <div rktStackItem variant="bodyTwo" class="table-of-contents--contents">
                        <a rktLink href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a>
                        <a rktLink href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>
                        <a rktLink href="#/privacy-policy-spanish#how-we-use">Cómo usamos su información</a>
                        <a rktLink href="#/privacy-policy-spanish#how-we-share">Cómo compartimos su información</a>
                        <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a>
                        <a rktLink href="#/privacy-policy-spanish#childrens-privacy">Privacidad de los niños</a>
                        <a rktLink href="#/privacy-policy-spanish#california-shine-the-light">Ley <i>Shine The Light de California</i></a>
                        <a rktLink href="#/privacy-policy-spanish#processing-of-information">Procesamiento de la información en los Estados Unidos y otros países</a>
                        <a rktLink href="#/privacy-policy-spanish#your-choices">Sus opciones</a>
                        <a rktLink href="#/privacy-policy-spanish#changes-to-this-policy">Cambios a esta política</a>
                        <a rktLink href="#/privacy-policy-spanish#contact-information">Información de contacto</a>
                        <a rktLink href="#/privacy-policy-spanish#ca-privacy-policy" class="link--purple">Otra información sobre sus derechos de privacidad estatales</a>
                      </div>
                  </rkt-stack>
              </mat-card>
          </div>
      </rkt-stack>
      <!-- nearly identical table of contents for smaller viewports: accordion at bottom of page -->
      <mat-accordion>
          <mat-expansion-panel class="rkt-AccordionPanel table-of-contents--narrow" *ngIf="showTableOfContents">
              <mat-expansion-panel-header>
                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                      <h3 class="rkt-Heading-40">Índice</h3>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div rktStackItem variant="bodyTwo" class="table-of-contents--contents">
                <a rktLink href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a>
                <a rktLink href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>
                <a rktLink href="#/privacy-policy-spanish#how-we-use">Cómo usamos su información</a>
                <a rktLink href="#/privacy-policy-spanish#how-we-share">Cómo compartimos su información</a>
                <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a>
                <a rktLink href="#/privacy-policy-spanish#childrens-privacy">Privacidad de los niños</a>
                <a rktLink href="#/privacy-policy-spanish#california-shine-the-light">Ley <i>Shine The Light de California</i></a>
                <a rktLink href="#/privacy-policy-spanish#processing-of-information">Procesamiento de la información en los Estados Unidos y otros países</a>
                <a rktLink href="#/privacy-policy-spanish#your-choices">Sus opciones</a>
                <a rktLink href="#/privacy-policy-spanish#changes-to-this-policy">Cambios a esta política</a>
                <a rktLink href="#/privacy-policy-spanish#contact-information">Información de contacto</a>
                <a rktLink href="#/privacy-policy-spanish#ca-privacy-policy" class="link--purple">Otra información sobre sus derechos de privacidad estatales</a>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>
<div *ngIf="showUpdatedPolicy" class="rkt-CenteredColumn privacy-policy" id="top">
  <div class="marginDiv">
      <!-- 3/5 stack w/privacy policy -->
      <rkt-stack splitAt="6">
          <div rktStackItem class="rkt-Stack__item--three-fifths@6">
            <!-- rocket logo -->
            <img *ngIf="!showNewLogo" class="rocket-logo" src="../../../../assets/img/logo/Rocket-Logo.svg">
            <!-- new rocket logo -->
            <img *ngIf="showNewLogo" class="rocket-new-logo" [src]="logoUrl">
              <!-- headers and copy -->
              <rkt-stack spacing="8">
                  <div rktStackItem id="rocket-privacy-policy" class="header-title">
                      <h1 class="rkt-Heading-28 rkt-Spacing--mb24">
                        Política de privacidad de la familia de compañías Rocket
                      </h1>
                      <!-- print button, shows only at small viewports (under 880px) -->
                      <div class="print-button--container print-button--narrow" *ngIf="showTableOfContents">
                          <button class="rkt-Button rkt-Button--secondary" mat-stroked-button color="accent"
                              id="print-button" (click)="printPrivacyPolicy()">Imprimir politica de privacidad</button>
                      </div>
                      <p class="rkt-Body-16 rkt-Spacing--mb24"><b>Fecha de vigencia</b>: 8 de enero de 2025</p>
                      <p class="rkt-Body-16 rkt-Spacing--mb24">
                        Esta Política de privacidad de la familia de compañías Rocket (“Política de privacidad”) describe cómo la familia de compañías Rocket (como se define en  
                        <a rktLink href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a> abajo) procesa la información recopilada en nuestros sitios web y 
                        aplicaciones (incluyendo los dispositivos móviles) o por medio de sus interacciones en línea con nosotros (colectivamente, nuestros “Servicios”).
                      </p>
                      <p class="rkt-Body-16 rkt-Spacing--mb24">
                        En algunos casos, es posible que le enviemos otros avisos complementarios o de privacidad en la medida en que se apliquen a usted por los 
                        productos y servicios que obtiene de nosotros o las diferentes leyes que se apliquen en su caso. 
                        Es posible que algunos estados apliquen derechos de privacidad extras. Consulte la sección
                        <a rktLink href="#/privacy-policy-spanish#ca-privacy-policy">Información extra sobre sus derechos de privacidad estatales para obtener más información.</a>.
                      </p>
                      <p class="rkt-Body-16 rkt-Spacing--mb24">
                          <b>
                            Cuando usa cualquiera de nuestros servicios, reconoce las prácticas de privacidad que describimos en esta Política de privacidad del grupo de empresas Rocket. 
                            También reconoce que leyó esta política y otras políticas descritas. </b>
                      </p>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                      class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Who We Are" section -->
                  <div rktStackItem id="who-we-are">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb24">Quiénes somos</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb24">
                        Cuando decimos "<b>el grupo de empresas Rocket</b>", nos referimos a las compañías que se mencionan abajo, incluyendo Rocket, LLC (anteriormente conocida como “RKT Holdings, LLC”) y las empresas que controla Rocket, LLC y están en la plataforma de Rocket. 
                        Cuando usamos “Rocket”, “nosotros” o “nos” nos referimos a la compañía operativa correspondiente de Rocket relevante en la familia de compañías Rocket implicada en la actividad del procesamiento. 
                        Esta política no se aplica a los afiliados de Rocket que no son parte de la familia de compañías Rocket (“Afiliados”). Las prácticas sobre datos de los afiliados se rigen por sus propias políticas de privacidad.
                      </p>
                      <div rktStackItem>
                          <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider>
                          </div>
                      </div>
                      <mat-accordion class="rkt-Spacing--mb24">
                          <mat-expansion-panel class="rkt-AccordionPanel rkt-Spacing--mb24"
                              idString="rocket-family-of-companies" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Familia de compañías Rocket</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <div class="rkt-AccordionPanel__content">
                                  <p class="rkt-Body-14 rkt-Spacing--mb8">La Familia de compañías Rocket incluye:</p>
                                  <ul>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Mortgage</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Loans</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Homes</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Money</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">ForSaleByOwner.com, una compañía Rocket</p>
                                      </li>
                                      <li>
                                          <p class="rkt-Body-14">Rocket Card</p>
                                      </li>
                                  </ul>
                              </div>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Information We Collect" section -->
                  <div rktStackItem id="information-we-collect">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Información que recopilamos</h1>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="information-you-provide"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que usted da</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">Recopilamos la información que usted nos da. Por ejemplo, recopilamos su información cuando crea una cuenta de Rocket, 
                                completa un formulario o envía contenido por medio de nuestros servicios; nos los refiere uno de nuestros socios profesionales externos; hace una compra; 
                                se comunica con nosotros mediante plataformas de terceros; participa en un concurso, promoción o encuesta; pide información sobre nuestros servicios, usa 
                                la función de chat en nuestros servicios o se comunica con nosotros de cualquier otra manera. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                La información específica que recopilamos depende del contexto en que usted nos la dé, y podría incluir:
                              </p>
                              <ul>
                                <li>Nombre</li>
                                <li>Dirección de correo electrónico</li>
                                <li>Dirección postal</li>
                                <li>Teléfono</li>
                                <li>Número del Seguro Social</li>
                                <li>Ubicación y valor aproximado de la propiedad</li>
                                <li>Edad o fecha de nacimiento</li>
                                <li>Información demográfica, como raza, origen étnico y sexo recopilada según requisitos federales</li>
                                <li>Productos o servicios de préstamos relacionados que haya usado en el pasado</li>
                                <li>Información financiera, como ingresos, activos y patrimonio neto</li>
                                <li>Estado de la propiedad de la vivienda y estado de empleo</li>
                                <li>Información del seguro</li>
                                <li>Otros datos de perfil, incluyendo sus intereses y preferencias</li>
                                <li>Cualquier otra información que nos da </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="information-we-automatically-collect" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que recopilamos automáticamente</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">Recopilamos automáticamente cierta información sobre sus interacciones con nosotros o nuestros Servicios, incluyendo:</p>
                              <ul>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información de transacciones: </b>cuando hace una compra o completa una transacción, recopilamos información sobre la transacción, 
                                        como información del producto o servicio, información económica, fecha y lugar de la transacción.
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información del dispositivo y el uso: </b>recopilamos información sobre cómo accede a nuestros servicios, incluyendo los datos sobre el 
                                        dispositivo y la red que usa, como el modelo de hardware, la versión del sistema operativo, red móvil, dirección IP, identificadores únicos del dispositivo, configuración regional 
                                        y de idioma del dispositivo, tipo de navegador y versión de la aplicación. También recopilamos información sobre su actividad en nuestros servicios, como tiempos de acceso, páginas 
                                        visitadas, enlaces en los que hizo clic, productos y servicios considerados y la página que visitó antes de navegar a nuestros servicios.
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Su contenido: </b>según usted lo permita mediante su dispositivo o aplicación, podríamos recopilar información y contenido almacenados en su dispositivo, como fotos.
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información de la ubicación: </b>según los permisos de su dispositivo, podríamos recopilar información sobre el lugar exacto de su dispositivo. Puede detener 
                                        la recopilación de información del lugar exacto en cualquier momento (consulte la sección de
                                        <a rktLink href="#/privacy-policy-spanish#your-choices">Sus opciones</a> abajo para obtener más información).
                                      </p>
                                  </li>
                                  <li>
                                      <p class="rkt-Body-14 rkt-Spacing--mb8"><b>Información recopilada por <i>cookies</i> y tecnologías de seguimiento similares: </b> nosotros (y los que hacen trabajos para nosotros) usamos tecnologías 
                                        de seguimiento, como cookies y balizas web, para recopilar información sobre sus interacciones con los Servicios.
                                      </p> 
                                      <ul>
                                          <li>
                                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                Las <b>cookies</b> son pequeños archivos de datos almacenados en su disco duro o en la memoria del dispositivo que nos ayudan a mejorar nuestros servicios 
                                                y su experiencia, ver qué áreas y características de nuestros servicios son populares y los correos electrónicos y anuncios que ve, autenticar los usuarios, 
                                                reconstruir u observar la actividad de una sesión o la de un usuario para la solución de problemas, resolver problemas y otros propósitos, personalizar anuncios 
                                                y hacer análisis, y contar las visitas a nuestros sitios web.
                                              </p>
                                          </li>
                                          <li>
                                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                Las <b>balizas web</b> (también conocidas como <b>etiquetas de píxeles</b> o <b>GIF transparentes</b>) son imágenes electrónicas que usamos en nuestros
                                                servicios y en nuestros correos electrónicos para enviar <i>cookies</i>, contar visitas y entender el uso y la eficacia de la campaña. En nuestras 
                                                aplicaciones móviles, también podríamos usar tecnologías que no están basadas en el navegador como las cookies.
                                              </p>
                                          </li>
                                          <li>
                                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                                Por ejemplo, nuestras aplicaciones pueden incluir <b>kits de desarrollo de software</b> (conocidos como SDK), que son un código que envía a un servidor 
                                                información sobre el uso que usted les da. Estos SDK nos permiten dar seguimiento a nuestras conversiones, mostrarle publicidad dentro y fuera del 
                                                servicio y darle otras funciones.
                                                <b>Para obtener más información sobre estas tecnologías de seguimiento y cómo deshabilitarlas, 
                                                  consulte la sección <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a> abajo.</b>
                                              </p>
                                          </li>
                                      </ul>
                                  </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="information-we-collect"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que recopilamos de otras fuentes</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Obtenemos información de fuentes externas en ciertas situaciones. Por ejemplo, podríamos recopilar información sobre usted de servicios de verificación 
                                de identidad u otros servicios de verificación, agencias de crédito (incluyendo su informe de crédito), redes de publicidad, proveedores de listas de 
                                distribución de correo, proveedores de servicios y corredores de datos. Nuestros servicios también podrían permitirle o pedirle que integre o conecte su 
                                cuenta Rocket con servicios de terceros por medio de proveedores de servicios (como Plaid). Esto facilita completar su transacción con nosotros al importar 
                                directamente cierta información, como los datos de su cuenta bancaria, en lugar de tener que cargarla manualmente. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                La información que recibimos de esos terceros varía dependiendo de la información que esas entidades faciliten. Algunos ejemplos de los tipos de información que recibimos incluyen información 
                                de cuentas financieras, de saldos de las cuentas, de cuentas de crédito, de cuentas de préstamos, de cuentas de inversión, identificadores e información sobre propietarios de cuentas, 
                                de transacciones de cuentas e información laboral. 
                              </p>

                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Además, si crea o inicia sesión en su cuenta Rocket usando una plataforma de terceros, como Facebook o Google, tendremos acceso a cierta 
                                información suya en esa plataforma, como su nombre, fecha de nacimiento y foto de perfil, de acuerdo con los procedimientos de autorización de la plataforma. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="information-we-derive"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información que deducimos</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Podemos deducir información o hacer inferencias sobre usted según la información que recopilamos. Por ejemplo, podemos hacer inferencias 
                                sobre su ubicación según su dirección IP, ciudad o código postal, o que está buscando comprar ciertos productos basándonos en la 
                                información que nos da, su historial de navegación y las compras que haya hecho. Podemos incorporar productos y servicios que 
                                utilizan inteligencia artificial, incluyendo modelos de lenguaje avanzado y aprendizaje automático, para obtener esta información y hacer estas inferencias. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="plaid-technologies"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Plaid Technologies</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Rocket le permite usar Plaid Technologies, Inc. para recopilar datos de instituciones financieras. Cuando usa los Servicios, usted reconoce 
                                y acepta que la información se procesará de acuerdo con la
                                <a rktLink href="https://plaid.com/legal/#consumers" target="blank">Política de privacidad de Plaid,</a> y usted les da a Rocket y a Plaid los
                                mismos derechos, facultades y autoridad que se especifican en esa política.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "How We Use Your Information" section -->
                  <div rktStackItem id="how-we-use">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Cómo usamos su información</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">Usamos la información que recopilamos para prestarle nuestros servicios.</p>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="other-reasons-to-collect"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Otros motivos para recopilar información</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                También la usamos para:
                              </p>
                              <ul>
                                  <li>
                                    Cumplir o prestar los servicios que usted haya pedido
                                  </li>
                                  <li>
                                    Ofrecer, mantener, mejorar y crear nuevos productos, servicios y características, incluyendo la depuración y reparación de errores en nuestros
                                    Servicios
                                  </li>
                                  <li>
                                    Personalizar su experiencia con nosotros
                                  </li>
                                  <li>
                                    Enviarle avisos técnicos, alertas de seguridad, mensajes de soporte y otros mensajes sobre las transacciones o la relación comercial, incluyendo
                                    respuestas a sus consultas y comentarios
                                  </li>
                                  <li>
                                    Comunicarnos con usted sobre productos, servicios y eventos que ofrecen Rocket y otros, y enviarle noticias e información que creamos que 
                                    le interesarían. <b> <a rktLink href="#/privacy-policy-spanish#your-choices">Consulte la sección Sus opciones abajo para</a> obtener información sobre cómo 
                                    dejar de recibir estas comunicaciones en cualquier momento consulte abajo la sección Sus opciones.</b>
                                  </li>
                                  <li>
                                    Monitorear y analizar tendencias, uso y actividades relacionadas con nuestros productos y servicios
                                  </li>
                                  <li>
                                    Personalizar los anuncios que ve en plataformas y sitios web de terceros. <b>Para obtener más información, consulte la sección 
                                      <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis abajo. </a></b>
                                  </li>
                                  <li>
                                    Mostrarle ofertas de servicios de terceros personalizados para usted, según la información suya que hayamos recopilado
                                  </li>
                                  <li>
                                    Facilitar concursos, sorteos y promociones, y procesar y entregar entradas y premios
                                  </li>
                                  <li>
                                    Detectar, investigar y ayudar a prevenir incidentes de seguridad y otras actividades maliciosas, engañosas, fraudulentas o 
                                    ilegales, y ayudar a proteger los derechos y la propiedad de Rocket y de otros
                                  </li>
                                  <li>
                                    Cumplir nuestras obligaciones legales y financierasComply with our legal and financial obligations
                                  </li>
                                  <li>
                                    Crear información sin identificación, anónima o agrupada
                                  </li>
                                  <li>
                                    Cumplir cualquier otro propósito bajo su dirección.
                                  </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "How We Share Your Information" section -->
                  <div rktStackItem id="how-we-share">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Cómo compartimos su información</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Compartimos información personal en las siguientes circunstancias o como se describe en esta política.
                      </p>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="sharing-among-rocket-family-of-companies" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Cómo la compartimos entre la familia de compañías Rocket</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>La familia de compañías Rocket</b> usa y comparte su información internamente según esta 
                                <b>política de privacidad de la familia de compañías Rocket</b>. Esto le permite <b>a la Familia de compañías Rocket</b> administrar, prestar y
                                ofrecerle varios de nuestros servicios durante el amplio ciclo de vida de sus necesidades personales y financieras.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="sharing-with-affiliates"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Cómo la compartimos con afiliados</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Rocket también comparte su información con sus afiliados. Por ejemplo, compartimos, o podemos compartir, su información:
                              </p>
                              <ul>
                                  <li>
                                    Con Afiliados que actúan como proveedores, prestadores de servicios y consultores que acceden a información personal para prestar servicios por nosotros, como 
                                    las compañías que nos ayudan con los servicios de marketing 
                                  </li>
                                  <li>
                                    Con afiliados para sus propios servicios y marketing
                                  </li>
                                  <li>
                                    Con afiliados para fines de informes para agencias reguladoras y registro de valores medibles del rendimiento
                                  </li>
                                  <li>
                                    Con afiliados si creemos (1) que sus acciones son incongruentes con nuestros acuerdos o políticas del usuario, (2) que ha infringido
                                    la ley o (3) que es necesario para proteger los derechos, la propiedad y la seguridad de Rocket, nuestros usuarios, el público u otros                    
                                  </li>
                                  <li>
                                    En relación con, o durante las negociaciones relacionadas a cualquier fusión, venta de activos de la compañía, financiamiento o 
                                    adquisición de todo o de una parte de nuestro negocio por otra compañía. 
                                  </li>
                                  <li>
                                    Con afiliados con su consentimiento o por instrucciones suyas
                                  </li>
                              </ul>
                              <br>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                También compartimos información agrupada o sin identificación que no se pueda usar razonablemente para identificarlo.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="sharing-with-other-third-parties"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Cómo la compartimos con terceros</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Rocket comparte su información con terceros no afiliados en las siguientes circunstancias:
                              </p>
                              <ul>
                                  <li>
                                    Compartimos información personal con proveedores de productos y servicios y con consultores que acceden a información personal 
                                    para prestarnos servicios, como compañías que nos ayudan con alojamiento web, envío y entrega, procesamiento
                                    de pagos, prevención del fraude, servicio de atención al cliente, análisis de datos, títulos de propiedad y servicios de tasación, soporte técnico, mercadeo y publicidad.                    
                                  </li>
                                  <li>
                                    Si usted envía una reseña de algún producto o publica contenido en otra área pública de nuestros servicios, compartimos esa información 
                                    públicamente en nuestros servicios.
                                  </li>
                                  <li>
                                    Si elige usar las integraciones que ofrecemos en nuestros servicios, como compartir su ubicación por medio de nuestra integración con Google 
                                    Maps, podríamos compartir cierta información con los socios de la integración. 
                                  </li>
                                  <li>
                                    Compartimos información personal con terceros para los propios servicios de ellos y para fines de mercadeo, análisis y, en algunos casos, para comercializar 
                                    y ofrecer productos y servicios junto con terceros. 
                                  </li>
                                  <li>
                                    Cuando pide información sobre los servicios que ofrecen otras compañías, podríamos revelar información sobre usted a esas compañías para que nosotros o 
                                    ellas podamos enviarle avisos y determinar si califica para recibir productos o servicios. 
                                  </li>
                                  <li>
                                    Podríamos revelar información personal si creemos que la revelación se hace según cualquier ley o proceso legal aplicable, o si así lo 
                                    exigen las leyes o procesos legales, incluyendo solicitudes legales de las autoridades para cumplir los requisitos de seguridad nacional o de aplicación de la ley. 
                                  </li>
                                  <li>
                                    Podríamos compartir información personal si creemos (1) que sus acciones son incongruentes con nuestros acuerdos o políticas del usuario, 
                                    (2) que ha infringido la ley o (3) que es necesario para proteger los derechos, la propiedad y la seguridad de Rocket, nuestros usuarios, el público u otros. 
                                  </li>
                                  <li>
                                    Compartimos información personal con nuestros abogados y otros asesores profesionales cuando es necesario para recibir asesoría o 
                                    proteger y administrar nuestros intereses comerciales.
                                  </li>
                                  <li>
                                    Podríamos compartir información personal en relación con, o durante las negociaciones relacionadas a cualquier fusión, venta de activos 
                                    de la compañía, financiamiento o adquisición de todo o de una parte de nuestro negocio por otra compañía. 
                                  </li>
                                  <li>
                                    Compartimos información personal con su consentimiento o por instrucciones suyas incluyendo, entre otros, mediante integraciones de terceros que usted elija habilitar. 
                                    Esto incluye compartir información personal con profesionales externos que identifique, incluyendo su agente inmobiliario, asesor financiero, representante legal u otro profesional. 
                                  </li>
                                  <li>
                                    También compartimos información agrupada o sin identificación que no se pueda usar razonablemente para identificarlo.
                                  </li>
                                  <li>
                                    Si aceptó o dio su consentimiento para recibir mensajes de texto, dicho consentimiento no se compartirá con terceros.  
                                  </li>
                              </ul>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Advertising and Analytics" section -->
                  <div rktStackItem id="advertising-and-analytics">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Publicidad y análisis</h1>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="web-and-mobile-apps"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Web y aplicaciones móviles</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Compartimos información personal para permitir que otros presten servicios de análisis y publiquen anuncios en la web y en aplicaciones 
                                móviles. Estas entidades pueden usar <b>cookies, balizas web, identificadores de dispositivos</b> y otras tecnologías para recopilar 
                                información sobre cómo usa nuestros servicios y otros sitios web y aplicaciones, incluyendo su dirección IP, navegador web, información 
                                de redes móviles, páginas vistas, tiempo en las páginas o aplicaciones móviles, enlaces en los que hizo clic e información de conversión.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Esta información la pueden usar Rocket y otros para, entre otras cosas, analizar y rastrear datos, reconstruir u observar la actividad 
                                relacionada con una sesión, determinar la popularidad de cierto contenido, mostrar publicidad y contenido según sus intereses en 
                                nuestros servicios y otros sitios web, y comprender mejor su actividad en línea.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>
                                  Para obtener más información sobre los anuncios según sus intereses, o para que deje de usarse su información de navegación web con fines 
                                  de publicidad conductual, visite <a rktLink href="http://www.aboutads.info/choices" target="blank">www.aboutads.info/choices</a>. 
                                </b>
                                Es posible que su dispositivo también incluya una función (<i>“Limit Ad Tracking”</i> [Limitar el seguimiento de anuncios] en iOS, u 
                                <i>“Opt Out of Interest-Based Ads”</i> [Dejar de recibir anuncios según mis intereses] u <i>“Opt Out of Ads Personalization”</i> 
                                [Dejar de personalizar mis anuncios] en Android) que le permite evitar que se recopile cierta información mediante aplicaciones móviles y que se usa 
                                con fines de publicidad conductual. Las leyes de privacidad de algunos estados también les dan a sus residentes derechos relacionados a la exclusión 
                                voluntaria directa del intercambio de información personal con fines de publicidad basada en el contexto o en intereses. 
                                Para obtener más información, consulte la sección Otra información sobre sus derechos de privacidad estatales, abajo.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="third-parties" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Plataformas de terceros</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                También compartimos información personal para trabajar con terceros para mostrarle anuncios como parte de campañas personalizadas en plataformas 
                                de terceros (como Facebook). Como parte de esas campañas publicitarias, nosotros o las plataformas de terceros podríamos convertir información sobre usted, como 
                                la dirección de correo electrónico o el teléfono, en un valor único que se puede combinar con una cuenta de usuario en esas plataformas para que podamos conocer 
                                sus intereses y ofrecerle publicidad personalizada según sus intereses. Tenga en cuenta que las plataformas de terceros pueden darle la opción de ver o no este 
                                tipo de anuncios personalizados.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Children's Privacy" section -->
                  <div rktStackItem id="childrens-privacy">
                    <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Privacidad de los niños</h1>
                    <p class="rkt-Body-14 rkt-Spacing--mb8">
                      Los productos Rocket no están destinados para niños. No dirigimos nuestros servicios a niños menores de 16 años, ni intentamos pedir ni recibir de manera intencional 
                      información de niños.
                    </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "California Shine the Light" section -->
                  <div rktStackItem id="california-shine-the-light">
                    <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Ley Shine The Light de California</h1>
                    <p class="rkt-Body-14 rkt-Spacing--mb8">
                      La ley de California les permite a los residentes de California pedir cierta información sobre cómo se comparten sus datos con terceros con fines 
                      de marketing directo. Si es residente de California y quiere pedir esa información, llámenos al <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                    </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Processing of Information" section -->
                  <div rktStackItem id="processing-of-information">
                    <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Procesamiento de la información en los Estados Unidos y otros países</h1>
                    <p class="rkt-Body-14 rkt-Spacing--mb8">
                      Rocket tiene su sede en los Estados Unidos y la familia de compañías Rocket ofrece bienes y servicios a consumidores en los Estados Unidos.
                      Tenemos operaciones y proveedores en los Estados Unidos y otros países. Por lo tanto, nosotros y los que prestan servicios por nosotros, podemos 
                      transferir, almacenar o acceder a su información personal en jurisdicciones que podrían no tener niveles de protección de datos que sean equivalentes 
                      a los de la jurisdicción donde vive.
                    </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Your Choices" section -->
                  <div rktStackItem id="your-choices">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Sus opciones</h1>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                        <mat-accordion>
                            <mat-expansion-panel class="rkt-AccordionPanel" idString="communication-choices"
                                [expanded]="true">
                                <mat-expansion-panel-header>
                                    <mat-panel-title class="rkt-AccordionPanel__header-title">
                                        <h3 class="rkt-Heading-28">Opciones de comunicación</h3>
                                    </mat-panel-title>
                                </mat-expansion-panel-header>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                  Puede darse de baja de los correos electrónicos promocionales siguiendo el enlace de 
                                  cancelación de suscripción en los mensajes de correo electrónico o actualizando sus preferencias 
                                  con nosotros. También puede optar por no recibir ciertas llamadas telefónicas y mensajes de 
                                  texto. Los procesos de exclusión voluntaria para llamadas telefónicas y mensajes de texto varían 
                                  según la compañía correspondiente en la familia de compañías Rocket. Visite cada sitio web para 
                                  obtener más información. 
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                  Nosotros y los proveedores de servicios externos grabamos nuestras llamadas telefónicas para 
                                  fines de control de calidad, detección de fraude, resolución de problemas y gestión de quejas. 
                                  Si no quiere que se graben las llamadas telefónicas, debe optar por un método de comunicación 
                                  alternativo, como el correo electrónico.
                                </p>
                                <p class="rkt-Body-14 rkt-Spacing--mb8">
                                  Los chats de nuestro sitio web y la aplicación móvil usan tecnología de IA y los grabamos nosotros 
                                  y terceros con fines de control de calidad. Si no quiere que se graben las llamadas telefónicas, 
                                  debe cerrar la función de chat y optar por otro método de comunicación. 
                                </p>
                            </mat-expansion-panel>
                        </mat-accordion>
                        <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="right-to-correct-account-information" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a corregir la información de la cuenta</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Puede actualizar y corregir cierta información de la cuenta, o eliminar la cuenta, iniciando sesión o llamándonos al
                                <a rktLink href="tel:888-444-7492">888-444-7492</a>. Tenga en cuenta que podríamos retener cierta información según lo exija la 
                                ley o para nuestros fines comerciales legítimos. Para obtener más información, consulte la sección Otra información sobre 
                                sus derechos de privacidad estatales.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="location-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Información de la ubicación</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Cuando inicie por primera vez cualquiera de nuestras aplicaciones móviles que recopilan información de ubicación exacta, o cuando visite 
                                partes de nuestros servicios que recopilan información de ubicación exacta,se le pedirá que dé su consentimiento para que la aplicación 
                                recopile esta información. Si inicialmente autoriza que recopilemos la información de la ubicación, después puede detener la recopilación de esa 
                                información en cualquier momento cambiando las preferencias en su dispositivo móvil. Es importante mencionar que es posible que algunos servicios 
                                no funcionen sin la información de ubicación exacta. También puede bloquear una aplicación móvil para que ya no obtenga información de ubicación 
                                siguiendo el proceso de desinstalación estándar para eliminar la aplicación de su dispositivo. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="do-not-track-signals"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Señales para evitar el rastreo</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Es posible que esté usando un navegador de Internet que pueda comunicar sus preferencias de privacidad al sitio web, incluyendo solicitudes de no 
                                rastrear su historial de uso y navegación. Nuestros sitios web están configurados para reconocer esas señales. Si reconocemos una de estas señales —que 
                                su navegador puede denominar o etiquetar como señales de “no rastrear” o “preferencia de exclusión voluntaria” o “preferencia global”—, le damos la opción 
                                de enviar una solicitud de exclusión voluntaria de la venta/compartición. Para obtener más información sobre excluirse de la venta/compartir información 
                                personal, consulte la sección Otra información sobre sus derechos de privacidad estatales, abajo.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>

                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                    class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
            <!-- "Security" section -->
            <div rktStackItem id="security">
                <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Seguridad</h1>
                <p class="rkt-Body-14 rkt-Spacing--mb8">
                  Utilizamos medidas razonables para proteger la confidencialidad y seguridad de la 
                  información personal que nos proporciona o que de otro modo recopilamos y procesamos.  
                </p>
                <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
            </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Changes To This Policy" section -->
                  <div rktStackItem id="changes-to-this-policy">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Cambios a esta política</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Podemos cambiar esta <b>política de privacidad de la familia de compañías Rocket</b> cada cierto tiempo. Si hacemos cambios, se lo avisaremos 
                        cambiando la fecha de la parte superior de esta política y, en algunos casos, podríamos decírselo de otra manera, como publicando una 
                        declaración en nuestro sitio web o enviándole un aviso. Le recomendamos que revise esta política con regularidad para mantenerse informado 
                        sobre nuestras prácticas de información y las opciones que tiene disponibles.
                      </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <div rktStackItem id="contact-information">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Información de contacto</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Si tiene preguntas sobre esta <b>política de privacidad de la familia de compañías Rocket</b>, nuestras prácticas de información u otros temas 
                        de privacidad relacionados con nuestros servicios, llámenos al <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                      </p>
                      <a rktLink class="top-link" href="#" (click)="scrollToTop($event)">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Additional Information" section -->
                  <div rktStackItem id="additional-information">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">OTRA INFORMACIÓN SOBRE SUS DERECHOS DE PRIVACIDAD ESTATALES</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Ciertos estados, exigen que demos más información sobre nuestras prácticas de privacidad y sus derechos de privacidad. Los 
                        estados , que han aprobado leyes de privacidad amplias, que les dan derechos de privacidad específicos a los residentes, 
                        incluyen: California, Colorado, Connecticut, Delaware,  Indiana, Iowa, Kentucky, Maryland, Minnesota, Montana, Nebraska, New Hampshire, 
                        New Jersey, Oregon, Rhode Island, Tennessee, Texas, Utah y Virginia. 
                        Abajo le damos más avisos sobre sus prácticas de privacidad y los derechos del consumidor relacionados con su información. 
                        Algunos derechos pueden depender de la fecha de vigencia de la ley y de si usted es residente de un estado con leyes de privacidad 
                        específicas, y de otros factores. Además, algunas de las compañías de la familia de compañías Rocket son instituciones financieras sujetas 
                        a otros marcos reglamentarios de privacidad como la Ley Gramm Leach Bliley (GLBA), y es posible que no se apliquen las revelaciones de 
                        privacidad ni los derechos descritos en esta sección. 
                      </p>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="notice-of-information-collected"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Aviso de información recopilada</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En los 12 meses anteriores, hemos recopilado las siguientes categorías de información personal:
                              </p>
                              <table mat-table [dataSource]="UpdatedInfoCollectedTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                  <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categoría</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.category }}
                                      </td>
                                  </ng-container>

                                  <ng-container matColumnDef="detail">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Ejemplos</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.detail }}
                                      </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                  <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                              </table>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>Para obtener más información sobre los puntos de datos precisos que recopilamos y las categorías de fuentes de dicha recopilación, consulte la sección
                                <a rktLink href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>.</b> Recopilamos información personal para los fines comerciales 
                                descritos en la sección <a rktLink href="#/privacy-policy-spanish#how-we-use">Cómo usamos la información</a>.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="disclosure-of-personal-information" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Revelación de la información personal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En los 12 meses anteriores, hemos revelado las siguientes categorías de información personal con fines comerciales a las siguientes 
                                categorías de destinatarios:
                              </p>
                              <table mat-table [dataSource]="UpdatedInfoSentTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                  <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categorías de la información personal</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.category }}
                                      </td>
                                  </ng-container>

                                  <ng-container matColumnDef="detail">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categorías de los destinatarios</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.detail }}
                                      </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                  <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                              </table>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="right-to-limit-processing-of-sensitive-personal-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a limitar el procesamiento de información personal sensible</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En California, tiene derecho a limitar nuestro procesamiento de su información personal sensible solo a los usos que sean necesarios 
                                para prestar los servicios o dar los bienes que razonablemente espera un consumidor promedio que pide dichos bienes o servicios. Cuando 
                                recopilamos su información personal sensible, la recopilamos y usamos para prestar los servicios o dar bienes para los 
                                que es necesaria y según lo que usted espera razonablemente.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Según otras leyes de privacidad estatales, solo se nos permite recopilar ciertos elementos de información personal sensible después de haber 
                                obtenido su consentimiento para hacerlo. Cuando sea necesario, obtendremos su consentimiento para recopilar y usar información personal sensible.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="sale-of-personal-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Venta de la información personal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Las leyes de privacidad estatales exigen ciertas revelaciones para las compañías que “venden” información 
                                personal según la ley de privacidad del estado correspondiente. Cada estado define la “venta” de datos de manera diferente. En algunos estados, 
                                la “venta” de datos significa ciertas circunstancias en las que Rocket ha compartido información personal con terceros o afiliados a 
                                cambio de una contraprestación valiosa. Otros estados definen la “venta” de datos como el intercambio de 
                                información personal de Rocket por un valor monetario con un tercero no afiliado. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Según esta definición, no “vendemos” su información personal.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Según la definición de California, “vendemos” información personal para permitir que nuestros Afiliados y ciertos terceros usen su 
                                información según sus políticas de privacidad. “Vendemos” las siguientes categorías de información personal a las siguientes categorías 
                                de terceros: 
                              </p>
                              <table mat-table [dataSource]="UpdatedSaleOfPersonalInfoTable" class="rkt-Table rkt-Table--striped-odd rkt-Spacing--mb24">
                                  <ng-container matColumnDef="category">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categoría de la información personal</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.category }}
                                      </td>
                                  </ng-container>

                                  <ng-container matColumnDef="detail">
                                      <th mat-header-cell *matHeaderCellDef class="rkt-Table__header">Categories of Third Parties</th>
                                      <td class="rkt-Table__cell" mat-cell *matCellDef="let element">
                                          {{ element.detail }}
                                      </td>
                                  </ng-container>

                                  <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
                                  <tr mat-row *matRowDef="let row; let even = even; columns: tableColumns"></tr>
                              </table>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="right-to-opt-out-of-selling"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a no participar en la venta</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Tiene el derecho de dejar de participar en la “venta” descrita arriba, en cualquier momento, visitando nuestra página web 
                                <a rktLink href="#/es/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information (No vendan mi información personal)</a>.
                                No vendemos ni compartimos de manera intencional información personal de consumidores menores de 16 años. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel"
                              idString="right-to-opt-out-of-personal-information" [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derecho a no participar en el intercambio de información que se comparte o usa en la publicidad dirigida</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Las leyes estatales de privacidad exigen que le informemos si “compartimos” su información personal con terceros. “Compartir” significa que ponemos 
                                a disponibilidad o revelamos su información a terceros para publicidad conductual de contexto cruzado. Además, “publicidad conductual de 
                                contexto cruzado” significa la publicidad dirigida a usted basándose en la información personal obtenida de su actividad en negocios, sitios 
                                web, aplicaciones y servicios distintos de nuestros servicios.  
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                De manera similar, las leyes de privacidad de otros estados nos exigen que informemos si usamos su información para 
                                publicidad dirigida. Publicidad dirigida significa mostrarle anuncios seleccionados basándonos en la información personal obtenida de sus 
                                actividades con el tiempo y en sitios web no afiliados o aplicaciones en línea para predecir sus preferencias o intereses. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En consecuencia, “compartimos” su información personal con terceros con fines de publicidad contextual cruzada, y la usamos para publicidad dirigida. 
                                Específicamente, compartimos sus identificadores y su actividad en Internet u otras redes con nuestros socios publicitarios. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>Tiene el derecho de optar por no participar en “compartir” ni recibir publicidad cirigida accediendo a nuestro sitio web
                                  <a rktLink href="#/do-not-sell-my-personal-information" target="blank">Do Not Sell My Personal Information (No vendan mi información personal)</a>.
                                  que está disponible como un enlace en el pie de página de nuestro sitio web. También respetamos las señales de “No rastrear” del navegador, como mencionamos arriba.</b>
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="retention-of-personal-information"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Retención de la información personal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                No retendremos su información personal por más tiempo del razonablemente necesario para cumplir los propósitos que revelamos en esta política. 
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider "></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="state-consumer-data-rights"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Derechos de información del consumidor a nivel estatal</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Sujeto a ciertas limitaciones, tiene derecho a (1) pedir más información sobre las categorías y piezas específicas de información personal 
                                que recopilamos, usamos, revelamos y vendemos, y para acceder a su información, (2) pedir que se elimine su información personal, (3) pedir 
                                la corrección de su información personal y (4) no ser discriminado por ejercer estos derechos. 
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                <b>Puede hacer estas solicitudes 
                                  <a rktLink href="https://www.rocketmortgage.com/privacy-rights/data-request?qls=QRD_12345678.0123456789" target="blank">
                                    presentando una solicitud en línea</a> o llamando al <a rktLink href="tel:888-444-7492">888-444-7492</a>.
                                </b> 
                                Necesitará proporcionar su información de contacto para iniciar una solicitud. Luego se le pedirá que confirme su identidad mediante una serie de preguntas de verificación.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Si recibimos su solicitud de un agente autorizado, podemos pedirle evidencia de que usted le dio a ese agente un poder, o que el agente 
                                tiene autoridad válida por escrito para presentar solicitudes para ejercer derechos en su nombre. 
                                <b>Si usted es un agente autorizado que quiere hacer una solicitud, comuníquese con nosotros como se menciona arriba.</b>
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="ca-financial-incentive-disclosure"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Revelación de incentivos económicos de California</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                La ley de privacidad de California exige que le informemos si le damos un programa, beneficio u otra oferta relacionada con la recopilación, 
                                eliminación o venta de información personal, que define como un “incentivo económico”. Podemos ofrecerle ciertos incentivos económicos que 
                                pueden dar lugar a diferentes precios, tasas o niveles de calidad (por ejemplo, concursos y programas de remisiones). Por medio de estas 
                                ofertas, los consumidores nos dan cierta información personal (como nombres, correos electrónicos, teléfonos) cuando deciden participar en 
                                nuestros programas. No hay obligación de participar y los consumidores pueden decidir dejar de hacerlo en cualquier momento. La información 
                                de cada programa está en la oferta del programa. Ofrecemos estos programas, entre otros, para mejorar nuestra relación con usted de manera 
                                que pueda disfrutar más de nuestros productos/servicios a un precio más bajo. Invertimos mucho en nuestro marketing y nuestras marcas, en 
                                parte para que podamos ofrecer programas a nuestros clientes. Los datos del consumidor son valiosos para nuestro negocio solo cuando se 
                                combinan con una cantidad suficiente de otros datos del consumidor y después de que los mejoramos con nuestras iniciativas aquí descritas. 
                                El valor para nuestro negocio de la información personal de cualquier consumidor individual depende de una serie de factores incluyendo, 
                                por ejemplo, si aprovecha cualquier oferta y en qué medida lo hace, si decide dejar de participar en alguna oferta, y en qué medida, y si 
                                podemos mejorar los datos mediante nuestras iniciativas aquí descritas. Nuestra capacidad para crear cualquier valor a partir de los 
                                programas se basa en gran medida en nuestra capacidad para aprovechar esas propiedades intelectuales. No calculamos el valor de los datos 
                                de los consumidores en nuestra contabilidad.
                              </p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <div class="rkt-Spacing--mb24"><mat-divider class="rkt-HorizontalDivider"></mat-divider></div>
                      <mat-accordion>
                          <mat-expansion-panel class="rkt-AccordionPanel" idString="ca-verifiable-client-requests"
                              [expanded]="true">
                              <mat-expansion-panel-header>
                                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                                      <h3 class="rkt-Heading-28">Solicitudes de datos del consumidor en California</h3>
                                  </mat-panel-title>
                              </mat-expansion-panel-header>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                En 2023, recibimos 421 solicitudes de datos del consumidor de consumidores de California. Esto incluye solicitudes de personas para saber, acceder o eliminar sus datos.
                                También recibimos 48,406 solicitudes que prefirieron no compartir información.
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">
                                Este es un resumen de esas solicitudes de los consumidores:
                                <br>
                                <b>119</b> solicitudes para saber/acceder
                                <br>
                                <b>302</b> solicitudes para eliminar
                              </p>
                              <p class="rkt-Body-14 rkt-Spacing--mb8">Nos ha llevado un promedio de 2 días procesar y responder a las solicitudes.</p>
                          </mat-expansion-panel>
                      </mat-accordion>
                      <div class="rkt-Spacing--mb24"></div>
                      <a rktLink class="top-link margin--bottom" href="#/privacy-policy-spanish#top">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"><mat-divider
                          class="rkt-HorizontalDivider rkt-HorizontalDivider--thick"></mat-divider></div>
                  <!-- "Federal Privacy Policy section -->
                  <div rktStackItem id="federal-privacy-notice" class="federal-privacy--narrow">
                      <h1 class="rkt-Heading-36 rkt-Spacing--mb8">Aviso de privacidad federal</h1>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                        Algunas compañías de la familia de compañías Rocket deben hacer un Aviso de privacidad adicional según la Ley Gramm-Leach-Bliley Act. 
                        Para su conveniencia, abajo incluimos estos avisos. 
                      </p>
                      <p class="rkt-Body-14 rkt-Spacing--mb8">
                          <a rktLink href="https://static.rocketloans.com/disclosures/our-privacy-notice.pdf"
                              target="blank">Rocket Loans</a>
                          <br>
                          <a rktLink href="https://www.rocketmoney.com/privacy-notice" target="blank">Rocket Money</a>
                          <br>
                          <a rktLink href="/assets/pdf/ServicingPrivacyPolicyV2.pdf" target="blank">Rocket
                              Mortgage</a>
                          <br>
                          <a rktLink href="https://www.rocketcard.com/privacy-notice" target="blank">Rocket Card</a>
                      </p>
                      <a rktLink class="top-link margin--bottom" href="#/privacy-policy-spanish#top">Volver arriba</a>
                  </div>
                  <div class="rkt-Spacing--mb24"></div>
              </rkt-stack>
          </div>
          <!-- 2/5 stack w/table of contents -->
          <div rktStackItem class="rkt-Stack__item--two-fifths@6 rightColumn">
              <!-- print button -->
              <div class="print-button--container print-button--wide">
                  <button class="rkt-Button rkt-Button--secondary" mat-stroked-button color="accent" id="print-button"
                      (click)="printPrivacyPolicy()">Imprimir politica de privacidad</button>
              </div>
              <!-- table of contents -->
              <mat-card idString="default" mat-card class="rkt-Card table-of-contents--wide rkt-Card--white">
                  <h3 class="rkt-Heading-40 rkt-Spacing--mb16">
                    Índice
                  </h3>
                  <rkt-stack spacing="8">
                      <div rktStackItem variant="bodyTwo" class="table-of-contents--contents">
                        <a rktLink href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a>
                        <a rktLink href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>
                        <a rktLink href="#/privacy-policy-spanish#how-we-use">Cómo usamos su información</a>
                        <a rktLink href="#/privacy-policy-spanish#how-we-share">Cómo compartimos su información</a>
                        <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a>
                        <a rktLink href="#/privacy-policy-spanish#childrens-privacy">Privacidad de los niños</a>
                        <a rktLink href="#/privacy-policy-spanish#california-shine-the-light">Ley <i>Shine The Light de California</i></a>
                        <a rktLink href="#/privacy-policy-spanish#processing-of-information">Procesamiento de la información en los Estados Unidos y otros países</a>
                        <a rktLink href="#/privacy-policy-spanish#your-choices">Sus opciones</a>
                        <a rktLink href="#/privacy-policy-spanish#changes-to-this-policy">Cambios a esta política</a>
                        <a rktLink href="#/privacy-policy-spanish#contact-information">Información de contacto</a>
                        <a rktLink href="#/privacy-policy-spanish#ca-privacy-policy" class="link--purple">Otra información sobre sus derechos de privacidad estatales</a>
                      </div>
                  </rkt-stack>
              </mat-card>
          </div>
      </rkt-stack>
      <!-- nearly identical table of contents for smaller viewports: accordion at bottom of page -->
      <mat-accordion>
          <mat-expansion-panel class="rkt-AccordionPanel table-of-contents--narrow" *ngIf="showTableOfContents">
              <mat-expansion-panel-header>
                  <mat-panel-title class="rkt-AccordionPanel__header-title">
                      <h3 class="rkt-Heading-40">Índice</h3>
                  </mat-panel-title>
              </mat-expansion-panel-header>
              <div rktStackItem variant="bodyTwo" class="table-of-contents--contents">
                <a rktLink href="#/privacy-policy-spanish#who-we-are">Quiénes somos</a>
                <a rktLink href="#/privacy-policy-spanish#information-we-collect">Información que recopilamos</a>
                <a rktLink href="#/privacy-policy-spanish#how-we-use">Cómo usamos su información</a>
                <a rktLink href="#/privacy-policy-spanish#how-we-share">Cómo compartimos su información</a>
                <a rktLink href="#/privacy-policy-spanish#advertising-and-analytics">Publicidad y análisis</a>
                <a rktLink href="#/privacy-policy-spanish#childrens-privacy">Privacidad de los niños</a>
                <a rktLink href="#/privacy-policy-spanish#california-shine-the-light">Ley <i>Shine The Light de California</i></a>
                <a rktLink href="#/privacy-policy-spanish#processing-of-information">Procesamiento de la información en los Estados Unidos y otros países</a>
                <a rktLink href="#/privacy-policy-spanish#your-choices">Sus opciones</a>
                <a rktLink href="#/privacy-policy-spanish#changes-to-this-policy">Cambios a esta política</a>
                <a rktLink href="#/privacy-policy-spanish#contact-information">Información de contacto</a>
                <a rktLink href="#/privacy-policy-spanish#ca-privacy-policy" class="link--purple">Otra información sobre sus derechos de privacidad estatales</a>
              </div>
          </mat-expansion-panel>
      </mat-accordion>
  </div>
</div>