import { Inject, Injectable, Optional } from '@angular/core';
import { APP_BASE_HREF, HashLocationStrategy, PlatformLocation } from '@angular/common';
import { UrlSerializer } from '@angular/router';

//Adapted from Nivrith's https://javascript.plainenglish.io/a-neat-trick-to-globally-preserve-query-params-in-angular-router-87276c604942
//Location Strategy will retain parameters passed to us outside of the route (Read: Auth0 error redirect). 
@Injectable()
export class PreserveHashLocationStrategy extends HashLocationStrategy {
    private get search(): string {
        return this.platformLocation?.search ?? '';
      }
      constructor(
        private platformLocation: PlatformLocation,
        private urlSerializer: UrlSerializer,
        @Optional() @Inject(APP_BASE_HREF) _baseHref?: string,
      ) {
        super(platformLocation, _baseHref);
      }
    override prepareExternalUrl(internal: string): string {
        //perform basic Hash Location strategy 
        const path = super.prepareExternalUrl(internal);
        
        const existingURLSearchParams = new URLSearchParams(this.search);
        const existingQueryParams = Object.fromEntries(existingURLSearchParams.entries());
        
        const urlTree = this.urlSerializer.parse(path);
        
        var nextQueryParams = urlTree.queryParams;
        urlTree.queryParams = { ...existingQueryParams, ...nextQueryParams};

        return urlTree.toString();
	}
}