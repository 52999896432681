import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PageSource } from 'app/models/page-source';
import { LoggerService } from 'app/services/generic/logging.service';
import { LogoService } from 'app/services/generic/logo-service';
import { environment } from 'environments/environment';

@Component({
  standalone: true,
  selector: 'error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss'],
  imports: [ CommonModule ],
  providers: [LoggerService]
})
export class ErrorComponent implements OnInit {

  public src?: PageSource
  public pageSource = PageSource;
  public showNewLogo: boolean = false;
  public logoUrl?: string;
  public errorDescription ?: string;

  constructor(   
     private readonly _activatedRoute: ActivatedRoute,
     private readonly logoSplitService: LogoService,
     private readonly logger: LoggerService
  ) {
  }

  async ngOnInit() {
    this.showNewLogo = await this.logoSplitService.getLogoTreatment();
    this.logoUrl = environment.logoUrls.rocket;
    this._activatedRoute.queryParams.subscribe(params => this.src = params['src']);
    this._activatedRoute.queryParams.subscribe(params => this.errorDescription = params['error_description']);
    if(this.errorDescription == null){
      this.errorDescription = new URLSearchParams(window.location.search).get('error_description') ?? "";
    }
    if(this.errorDescription == "This URL can be used only once" || this.errorDescription=="User account does not exist or verification code is invalid." || this.errorDescription == "Access expired."){
      this.src = this.pageSource.ValidationLinkReused;
    };
    this.logger.logtoSplunk(this.errorDescription);
  }

}
