import { Injectable } from '@angular/core';
import { SplitService } from 'app/modules/feature-flags/services/split.service';

@Injectable({
  providedIn: 'root'
})
export class LogoService {
  constructor(private readonly splitService: SplitService) {}

   async getLogoTreatment() {
     return await this.splitService.getTreatmentFor("show-new-logos");
   }
}
