import { Injectable, NgZone } from '@angular/core';
import { SplitFactory } from '@splitsoftware/splitio-browserjs';
import * as SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import { environment } from 'environments/environment';
import { fromEvent, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SplitService {

  constructor(private ngZone: NgZone) {}

  /**
  * The local reference to the Split SDK.
  */
  factory?: SplitIO.ISDK;
  client?: SplitIO.IClient;
 
  /**
  * Flag to determine if SDK is ready or not.
  */
  isReady = false;
 
  /**
  * The local reference to the list of treatments.
  */
  treatments?: SplitIO.Treatments;
 
  /**
  * The list of Features/Split from which to get the corresponding
  * treatments.
  */
  features: string[] = [
    'rocket-consent',
    'truebill-frontend',
    'truebill-asset-show',
    'tos-update',
    'rl-solar',
    'remove-rocketsolar-rocketauto',
    'username-conversion',
    'username-conversion-warning',
    'request-delete',
    'rocket-money',
    'reset-mfa',
    'mfa-self-service',
    'rockethomes-get-raid',
    'disable-account-comment-required',
    'enable-totp',
    'ttu-liv-chat',
    'mfa-numeric-keyboard',
    'ttu-rocketmoney-content',
    'show-new-logos',
    'updated-privacy-policy'
  ];

  userKey: string = "rocketaccount-web-app";
 
  /**
  * The local reference to the SDK's ready Observable.
  */
  subscription?: Subscription;
 
  /**
  * This method initializes the SDK with the required Browser APIKEY
  * and the 'key' according to the Traffic type set (ex.: an user id).
  *
  * @returns void
  */
  init(userKey: string): SplitIO.IClient | undefined {
    let config: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: environment.splitConfig.key,
        key: userKey
      }
    };
    let localConfig: SplitIO.IBrowserSettings = {
      core: {
        authorizationKey: 'localhost',
        key: 'localhost_key'
      }
    };
    // needs to run outside Angular to prevent e2e test timeouts
    this.ngZone.runOutsideAngular(() => {
      // don't create a new factory or client if one has already been instantiated
      if (!this.factory) {
        if (userKey !== 'localhost') {
          this.factory = SplitFactory(config)
        } else this.factory = SplitFactory(localConfig)
      }
      if (!this.client) this.client = this.factory.client(userKey);
      // verify if sdk is initialized
      this.verifyReady();
    });
    return this.client;
  }
 
  /**
  * Function to check if the SDK is ready, subscribe to an Observable
  * and set the isReady flag according to the result.
  *
  * @returns void
  */
  private verifyReady(): void {
    this.subscription = this.isReadyEvent().subscribe(
      () => {
        this.isReady = true;
      },
      (error) => {
        console.log('SplitService error: ' + error);
        this.isReady = false;
        if(this.subscription) {
          this.unsubscribe();
        }
      }
    );
  }

  /**
   * Function to return the observable used in verifyReady (used for mocking).
   * 
   * @returns Observable<unknown>
   */
  isReadyEvent() {
    return fromEvent(this.client!, this.client!.Event.SDK_READY);
  }
 
  /**
  * Function to get and set the treatments from the Split API.
  *
  * @returns void
  */
  getTreatments(): void {
    this.treatments = this.client!.getTreatments(this.features);
  }
 
  getTreatmentFor(featureName: string): Promise<boolean> {
    return new Promise<boolean>((res, rej) => {
      this.init(this.userKey)?.ready().then(() => {
        this.getTreatments();
        let featureFlagResult = null; // in case treatment is not found in Split
  
        if (this.treatments![featureName] === 'on') 
          featureFlagResult = true;
        else if (this.treatments![featureName] === 'off')
          featureFlagResult = false;
        
        if(featureName === 'rl-solar'){
          featureFlagResult = true;
        }
        // resolve the promise
        res(featureFlagResult!);
      });
    });
  }

  getTreatmentWithConfigFor(featureName: string): Promise<any> {
    return new Promise<any>((res) => {
      this.init(this.userKey)?.ready().then(() => {
        const splitResult: SplitIO.TreatmentWithConfig = this.client!.getTreatmentWithConfig(featureName);
        const configs: any = JSON.parse(splitResult.config!);
        const treatment: SplitIO.Treatment = splitResult.treatment;
        let featureFlagResult = null;

        if (treatment === 'on') 
          featureFlagResult = true;
        else if (treatment === 'off')
          featureFlagResult = false;
      
      res({active: featureFlagResult, config: configs});
      });
    });
  }

  /**
  * Function to unsubscribe the Observable from the SDK initialization.
  *
  * @returns void
  */
  unsubscribe(): void {
    this.subscription?.unsubscribe();
  }

  /**
  * Function to destroy the client.
  *
  * @returns void
  */
  destroy(): void {
    this.client?.destroy();
  }

  ngOnDestroy() {
    this.destroy();
  }
}
