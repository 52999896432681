import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { Location } from "@angular/common";
import { AppHookService } from 'app/services/app-hook.service';

@Component({
  selector: 'app-tos',
  templateUrl: './tos.component.html',
  styleUrls: ['./tos.component.scss']
})
export class TosComponent implements AfterViewInit {

  @Output() closed = new EventEmitter<any>();

  constructor(
    private _location: Location,
    private hooks: AppHookService,
  ) {

  }

  ngAfterViewInit() {
  }

  close() {
    this.closed.emit(true);
  }

  goBack() {
    this._location.back();
  }

  get isWebView() {
    return this.hooks.isWebView;
  }
}
